export default function columnsResetPasswordUsers(t) {
    var col = [
        {
            key: "displayName",
            visible: true,
            exportableCSV: true,
            label: t("Name"),
            id: "displayName",
            width: 300,
            format: (row) => <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
                {row.displayName}
            </span>
        },
        {
            key: "userPrincipalName",
            visible: true,
            exportableCSV: true,
            label: t("ID 365"),
            id: "userPrincipalName",
            width: 300,
            format: (row) => <span>{row.userPrincipalName}</span>,
        },
        {
            key: "password",
            visible: true,
            exportableCSV: true,
            label: t("Password"),
            id: "password",
            width: 300,
            format: (row) => <span>{row.password}</span>,
        },
    ];

    return col
}