import React from "react";
import LoadingSpinner from "../Animation/LoadingSpinner";

function Switch({ value, onClick, loading }) {

    function onSwitchChange() {
        const valueUpdated = !value;
        onClick(valueUpdated);
    }

    return loading ? (<LoadingSpinner />) : (
        <button type="button" onClick={onSwitchChange} aria-pressed="false" aria-labelledby="renew-headline" className={
            value ?
                "bg-green-600 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                : "bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        } >
            <span aria-hidden="true" className={value ?
                "translate-x-5 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                : "translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"}></span>
        </button >
    )
}

export default React.memo(Switch);