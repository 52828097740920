import { loginRequest, protectedResources } from "./config";
import { msalInstance } from "./AppProvider";


function verifyAuth(component) {
    if (msalInstance.getAllAccounts().length) {
        return component;
    }
}

function isUserAuthent() {
    return msalInstance.getAllAccounts() && msalInstance.getAllAccounts().length
}

function getUser() {
    if (msalInstance.getAllAccounts().length) {
        return msalInstance.getAllAccounts()[0];
    }
}

async function getAccessToken() {
    var account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        scopes: protectedResources.api.scopes,
        account: account
    });
    return response.accessToken;
}

function logout() {
    msalInstance.logoutRedirect();
}

async function getAccessTokenGraph() {
    var account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({ ...loginRequest, account: account });
    return response.accessToken;
}

export {
    verifyAuth,
    getUser,
    logout,
    isUserAuthent,
    getAccessToken,
    getAccessTokenGraph
};