import React from "react";

function Footer({ children }) {
  return (
    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
      <div className="space-x-3 flex justify-end">{children}</div>
    </div>
  );
}

export default React.memo(Footer)
