import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import { resetPasswordUsers, getUsersbyIds, isUserAdmin } from "../../requests/user";
import Table from "../../../components/Tables/Table";
import columnsResetPasswordUsers from "../../tables/ResetPasswordUsers";
import Button from "../../../components/Buttons/Button";
import { getSchool, getSchools } from "../../requests/school";
import { postMail } from "../../requests/mail";

const RestPasswordUsers = ({ t, TriggerComponent, usersId }) => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [school, setSchool] = useState({});
    const [schools, setSchools] = useState([]);
    const [admin, setAdmin] = useState(false);

    function handleOnOpen() {
        isUserAdmin().then(isAdmin => {
            setAdmin(isAdmin);
            if (isAdmin) {
                getSchools().then(schools => {
                    setSchools(schools);
                })
            } else {
                getSchool().then(sch => setSchool(sch));
            }
        })

        getUsersbyIds(usersId).then(usersAPI => {
            setUsers(usersAPI.value)
        })
    }

    function handleResetPassword() {
        setLoading(true);
        if (users && users.length) {

            var promises = [];
            users.forEach(u => {
                promises.push(resetPasswordUsers(u.id).then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        return res;
                    }
                }).then(res => {
                    var usersUpdated = users;
                    var index = usersUpdated.findIndex(uu => u.id === uu.id);
                    var userUpdated = usersUpdated[index];

                    if (res && res.password) {
                        userUpdated.password = res.password;
                    } else {
                        userUpdated.password = "error";
                    }

                    return userUpdated;
                }))
            })

            Promise.all(promises).then(usersAPI => {
                setUsers(usersAPI)
                setFinished(true);

                //handleMail
                if (usersAPI && usersAPI.length) {
                    if (admin) {
                        schools.forEach(sch => {
                            if (usersAPI.find(u => u.state === sch.uai)) {
                                //handleMail
                                var content = "Bonjour,<br /><br />Voici la liste des comptes Office 365 dont le mot de passe a été réinitialisé :<br />";

                                usersAPI.filter(u => u.state === sch.uai).forEach(u => {
                                    content += u.officeLocation + ";" + u.displayName + ";" + u.userPrincipalName + ";Mot de passe : " + u.password + "<br />";
                                })

                                content += "<br />Cordialement,<br /><br />Votre administrateur Office 365";
                                var recipients = sch.administrateur;
                                postMail("Réinitialisation de mot de passe", content, recipients);
                            }
                        })
                    } else {
                        //handleMail
                        var content = "Bonjour,<br /><br />Voici la liste des comptes Office 365 dont le mot de passe a été réinitialisé :<br />";

                        usersAPI.forEach(u => {
                            content += u.officeLocation + ";" + u.displayName + ";" + u.userPrincipalName + ";Mot de passe : " + u.password + "<br />";
                        })

                        content += "<br />Cordialement,<br /><br />Votre administrateur Office 365";
                        var recipients = school.administrateur;
                        postMail("Import CSV", content, recipients);
                    }
                }

            })
        }
    }

    return (
        <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
            {(setOpen) => (
                <div
                    className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
                >
                    <div className="flex-1">
                        <Header title={t("Reset password users")} description={t("Are you sure to reset the password of all the users listed below ?")} setOpen={setOpen} />
                        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <Table key="usersToResetPWD" data={users} columns={columnsResetPasswordUsers(t)} />
                        </div>
                    </div>
                    {!finished && <Footer>
                        <>
                            <Button
                                type="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => setOpen(false)}
                            >
                                {t("Cancel")}
                            </Button>
                            <Button
                                loading={loading}
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => { handleResetPassword() }}
                            >
                                {t("Reset password")}
                            </Button>
                        </>
                    </Footer>}
                </div>
            )}
        </WideDrawer>

    );
};

export default React.memo(withTranslation()(RestPasswordUsers));
