import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { logout } from "../../auth/verifyAuth";
import { Popover } from "@headlessui/react";
import MobileMenu from "./Mobile/Menu";
import { UserCircleIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Aside({ navigation, user, match, history, t }) {
  return (
    <Popover as="header" className="pb-24 bg-green-600">
      {({ open }) => (
        <>
          <div className="w-screen mx-auto px-4">
            <div className="relative py-5 flex items-center justify-center lg:justify-between">
              <div className="absolute left-0 flex-shrink-0 lg:static text-white">
                <img
                  className="h-10 w-auto"
                  src={process.env.PUBLIC_URL + "/assets/icons/logo.png"}
                  alt="Workflow"
                />
              </div>

              <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5 mr-4">
                <div>
                  {user && user.imageUrl ? <div className="rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100" onClick={() => { logout() }}>
                    <span className="sr-only">Open user menu</span>
                    <img className="h-8 w-8 rounded-full" src={user ? user.imageUrl : null} alt="" />
                  </div>
                    :
                    <UserCircleIcon color="white" width="3rem" onClick={() => { logout() }} />
                  }

                </div>
              </div>
              <MobileMenu mobileMenuOpen={open} navigation={navigation} user={user} />
            </div>

            <div className="hidden lg:block border-t border-white border-opacity-20 py-5">
              <div className="grid grid-cols-3 gap-8 items-center">
                <div className="col-span-2">
                  <nav className="flex space-x-4">
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                        }}
                        className={classNames(
                          item.path === match.path
                            ? "bg-green-800 text-white"
                            : "text-green-100 hover:bg-green-800 hover:text-white",
                          "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <span className="mt-2">{item.name}</span>
                      </div>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Popover>
  );
}

export default React.memo(withTranslation()(withRouter(Aside)));
