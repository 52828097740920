export default function generatePassword(len) {
    var length = (len) ? (len) : (10);
    var string = "azertyuiopqsdfghjklmwxcvbn";
    var maj = "AZERTYUIOPQSDFGHJKLMWXCVBN";
    var numeric = '0123456789';
    var password = "";
    var character = "";
    while (password.length < length) {
        var entity1 = Math.ceil(string.length * Math.random() * Math.random());
        var entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        var entity3 = Math.ceil(maj.length * Math.random() * Math.random());
        character += string.charAt(entity1);
        character += numeric.charAt(entity2);
        character += maj.charAt(entity3);
        password = character;
    }
    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
    return password.substr(0, len);
}