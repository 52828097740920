import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Global from "../../layout/Vertical/Global"; // Mettre Horizontal ou Vertical selon le setup qu'on veut
import NotFound from "../../pages/NotFound";
import { verifyAuth } from "../../auth/verifyAuth";
import routes from "./routes";

function Layout({ component: Component, path, ...props }) {
  return (
    <Route
      {...props}
      path={path}
      component={verifyAuth(() => {
        return (
          <Global>
            <Component />
          </Global>
        );
      })}
    />
  );
}

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        {/* <Suspense fallback={<Global component={Core.Loading} path="/" />}> */}
        <Switch>
          {routes.map((r, index) => {
            return (
              <Layout
                key={index}
                path={r.path}
                component={r.component}
                exact={r.exact}
              />
            );
          })}
          <Route>
            <Global>
              <NotFound />
            </Global>
          </Route>
        </Switch>
        {/* </Suspense> */}
      </BrowserRouter>
    );
  }
}

export default Router;
