import React from "react";
import { withTranslation } from "react-i18next";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

function Header({ t, title, description, setOpen, buttons }) {
  return (
    <div className="px-4 py-6 bg-gray-50 sm:px-6">
      <div className="flex items-start justify-between space-x-3">
        <div className="space-y-1">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            {title}
          </Dialog.Title>
          {description && (
            <p className="text-sm text-gray-500"> {description} </p>
          )}
        </div>
        <div className="h-7 flex items-center">
          {buttons}
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500"
            onClick={() => setOpen(false)}
          >
            <span className="sr-only">{t("Close panel")}</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default React.memo(withTranslation()(Header));
