import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import handleCSVImport from "../../functions/handleCSVImport";
import {
  getSchoolUsers
} from "../../requests/user";
import Structure from "../../../components/Forms/Structure";
import Button from "../../../components/Buttons/Button";
import { CSVLink } from "react-csv";
import { postGroupMember, postGroupOwner } from "../../requests/group";

const dataExample = [
  {
    email: "test.test@test.cneap.fr",
    owner: "oui"
  },
  {
    email: "marine.chloe@test.cneap.fr",
    owner: "non"
  },
];

const columnStaffExample = [
  {
    label: "Email",
    key: "email",
  },
  {
    label: "Propriétaire",
    key: "owner",
  }
];

const ImportGroupMember = ({ school, groupId, t, TriggerComponent, reloadData }) => {
  const [users, setUsers] = useState([]);

  function handleOnOpen() {
    getSchoolUsers(school.uai).then(usersAPI => {
      setUsers(usersAPI)
    })
  }

  function handleImportCSVStudent(e, setOpen) {
    handleCSVImport(e, (data) => {
      var promises = [];
      var promisesGO = [];
      var errors = [];
      data.forEach((d, key) => {
        if (d?.Email) {
          if (users?.find(u => u.userPrincipalName === d?.Email)) {
            var isOwner = d?.Propriétaire === "1" || d?.Propriétaire === "oui" || d?.Propriétaire === 1;
            var userId = users.find(u => u.userPrincipalName === d?.Email).id
            if (isOwner) {
              promises.push(postGroupMember(userId, groupId))
              promisesGO.push(postGroupOwner(userId, groupId))
            } else {
              promises.push(postGroupMember(userId, groupId))
            }
          } else {
            errors.push(d?.Email + " not found in school users")
          }
        } else {
          errors.push("No email found at line " + (key + 1))
        }
      });
      Promise.all(promises).then(() => {
        Promise.all(promisesGO).then(() => {
          reloadData()
          setOpen(false)
        })
      });
    });
  }


  return (
    <WideDrawer
      handleOnOpen={handleOnOpen}
      TriggerComponent={(onClick) => TriggerComponent(onClick, false)}
    >
      {(setOpen) => (
        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
          <div className="flex-1">
            <Header title={t("Import student")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="sample"
                title={t("Example")}
                component={() => (
                  <>
                    <CSVLink
                      data={dataExample}
                      headers={columnStaffExample}
                      separator={";"}
                      className=""
                      filename="ImportExemple.csv"
                    >
                      <Button>{t("Download example")}</Button>
                    </CSVLink>
                  </>
                )}
              />
              <Structure
                id="file"
                title={t("File") + " *"}
                component={() => (
                  <input
                    type="file"
                    name="uploadFile"
                    id="uploadfile"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={(e) => handleImportCSVStudent(e, setOpen)}
                  />
                )}
              />
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => setOpen(false)}
              >
                {t("Cancel")}
              </button>
            </>
          </Footer>
        </div>
      )}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(ImportGroupMember));
