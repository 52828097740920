import { getAccessToken } from "../../auth/verifyAuth"

async function getSchool() {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/school", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

async function getSchoolById(id) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/school/" + id, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

async function getSchoolByCity(city) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/schoolByCity/" + city, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

async function getSchools() {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/schools", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    }).then(res => {
        if(res && res.length)
        {
            // res.sort((a, b) => (a.intitule < b.intitule ? -1 : a.intitule > b.intitule ? 1 : 0));
            return res;
        }
    })
}

async function postSchool(school) {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/school", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(school),
    })
}

async function patchSchool(school) {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/school", {
        method: "PATCH",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(school),
    })
}

async function deleteSchool(schoolId) {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/school/" + schoolId, {
        method: "DELETE",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
}

export {
    getSchool,
    getSchoolById,
    getSchoolByCity,
    getSchools,
    postSchool,
    patchSchool,
    deleteSchool
}