export default function generateMailNickname(givenName, surname) {
    var mailNickname = "";
    if (givenName && surname) {
        mailNickname = givenName
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "").split(' ').join('').split('\'').join('') +
            "." +
            surname
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(" ", "").split(' ').join('').split('\'').join('');
    }
    return mailNickname;
}
