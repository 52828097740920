import React, { useState } from "react";

function DateSelector({ setFilteredData, name }) {
    const [value, setValue] = useState({
        startDate: null,
        endDate: null
    });

    const handleValueChange = (startDate, endDate) => {
        setValue({
            startDate: startDate,
            endDate: endDate
        });
        setFilteredData({
            startDate: startDate,
            endDate: endDate
        })
    };

    return <div className="flex vertical-align my-2 font-semibold text-sm">{name} :
        <input type="date" onChange={(ev) => handleValueChange(ev.target.value, value?.endDate)} className="mx-1 text-left sm:text-sm border border-gray-300 rounded-md pl-3 cursor-default sm:text-sm" /> -
        <input type="date" onChange={(ev) => handleValueChange(value?.startDate, ev.target.value)} className="mx-1 text-left sm:text-sm border border-gray-300 rounded-md pl-3 cursor-default sm:text-sm" />
    </div>
}

export default React.memo(DateSelector);