import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../Buttons/Button";
import ExportToCSV from "../Export/ExportToCSV";
import SearchBar from "./ToolBar/SearchBar";
import Select from "./ToolBar/Select";
import ToolBar from "./ToolBar/ToolBar";
import DateSelector from "./ToolBar/DateSelector";

function Table({ columns, data, t, actions, handleFilterChange }) {
  const [count, setCount] = useState(30);
  const [dataFiltered, setFilteredData] = useState([]);
  const [dataSearchBar, setDataSeachBar] = useState([]);
  const [selectBar, setSelectBar] = useState({});
  const [sortDirection, setSortDirection] = useState(-1);
  const [sortField, setSortField] = useState("");

  useEffect(() => {
    setFilteredData(data);
    setDataSeachBar(data);
    setSelectBar({});
    setCount(30);
  }, [data]);

  function handleSelectFilter() {
    setFilteredData([]);
    var dataFilteredUpdated = dataSearchBar;
    var columnsToFiltered =
      typeof columns === "function"
        ? columns(dataFilteredUpdated).filter((c) => c.filter)
        : columns.filter((c) => c.filter);
    if (columnsToFiltered && columnsToFiltered.length) {
      dataFilteredUpdated = dataFilteredUpdated.filter((d) => {
        var result = true;
        columnsToFiltered.forEach((c) => {
          if (selectBar[c.id] && c?.filter?.type === "date") {
            result = result && handleDateFilter(d[c.key], selectBar[c.id]?.endDate, selectBar[c.id]?.startDate)
          } else if (
            selectBar[c.id] &&
            selectBar[c.id].length &&
            !selectBar[c.id].find((s) => s === d[c.key])
          ) {
            result = false;
          }
        });
        return result;
      });
    }
    setFilteredData(dataFilteredUpdated);
    if (handleFilterChange && typeof handleFilterChange === "function") {
      handleFilterChange(dataFilteredUpdated);
    }
  }

  function handleHeaderSort(column, key) {
    var columnIsSortable = false;
    if (dataFiltered && dataFiltered.length) {
      columnIsSortable = dataFiltered?.find((x) => x ? x[key] && x[key] !== null : false)
        ? true
        : false;
    }

    return columnIsSortable ? (
      <b
        className="cursor-pointer"
        onClick={() => {
          setSortField(key);
          setSortDirection(-1 * sortDirection);
          var dataFilteredSorted = dataFiltered;
          dataFilteredSorted.sort((a, b) => {
            if (!a[key]) return 1;
            if (!b[key]) return -1;
            return a[key] < b[key] ? -1 * sortDirection : sortDirection;
          });
          setFilteredData(dataFilteredSorted);
        }}
      >
        {typeof column === "function" ? column() : column}
        {key === sortField ? <span className="text-lg">&#8661;</span> : ""}
      </b>
    ) : typeof column === "function" ? (
      column()
    ) : (
      column
    );
  }

  function handleDateFilter(s, dateBefore, dateAfter) {
    var dateC = new Date(s).getTime()
    var dateA = null
    var result = false
    if (dateBefore) {
      var dateB = new Date(dateBefore).getTime()
      if (dateAfter) {
        dateA = new Date(dateAfter).getTime()
        result = dateC <= dateB && dateC >= dateA
      } else {
        result = dateC <= dateB
      }
    } else {
      if (dateAfter) {
        dateA = new Date(dateAfter).getTime()
        result = dateC >= dateA
      } else {
        result = true
      }
    }
    return result
  }

  return (
    <>
      <ToolBar
        components={
          <>
            <h3 className="text-sm 2xl:text-base font-semibold p-2 pr-2 2xl:pr-4 truncate">
              {dataFiltered && dataFiltered.length} {t("results")}
            </h3>
            <span className="mx-3 2xl:mx-5 w-1 border-l" />
            <SearchBar
              data={data}
              setFilteredData={(value) => {
                setDataSeachBar(value);
                handleSelectFilter();
              }}
            />
            <span className="mx-3 2xl:mx-5 w-1 border-l" />
            {columns && typeof columns === "function"
              ? columns(dataFiltered).find((c) => c.filter)
                ? columns(dataFiltered)
                  .filter((c) => c.filter)
                  .map((c) => {
                    if (c.filter.type === "select") {
                      return (
                        <Select
                          key={c.key}
                          options={data
                            .map((d) => d[c.key])
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )}
                          selectedOptions={
                            selectBar[c.id] ? selectBar[c.id] : []
                          }
                          setFilteredData={(value) => {
                            var allSelectBar = selectBar;
                            setSelectBar([]);
                            if (allSelectBar[c.id]) {
                              if (
                                allSelectBar[c.id].find((s) => s === value)
                              ) {
                                allSelectBar[c.id] = allSelectBar[
                                  c.id
                                ].filter((s) => s !== value);
                              } else {
                                allSelectBar[c.id].push(value);
                              }
                            } else {
                              allSelectBar[c.id] = [value];
                            }
                            setSelectBar(allSelectBar);
                            handleSelectFilter();
                          }}
                          name={c.label}
                        />
                      );
                    } else if (c.filter.type === "date") {
                      return (
                        <div className="border-l ml-2 pl-2"><DateSelector setFilteredData={(dates) => {
                          setSelectBar(allSelectBar => {
                            allSelectBar[c.id] = dates;
                            return allSelectBar
                          });
                          handleSelectFilter();
                        }}
                          name={c.label} /></div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null
              : columns.find((c) => c.filter)
                ? columns
                  .filter((c) => c.filter)
                  .map((c) => {
                    if (c.filter.type === "select") {
                      return (
                        <Select
                          key={c.key}
                          options={data
                            .map((d) => d[c.key])
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )}
                          selectedOptions={
                            selectBar[c.id] ? selectBar[c.id] : []
                          }
                          setFilteredData={(value) => {
                            var allSelectBar = selectBar;
                            setSelectBar([]);
                            if (allSelectBar[c.id]) {
                              if (allSelectBar[c.id].find((s) => s === value)) {
                                allSelectBar[c.id] = allSelectBar[c.id].filter(
                                  (s) => s !== value
                                );
                              } else {
                                allSelectBar[c.id].push(value);
                              }
                            } else {
                              allSelectBar[c.id] = [value];
                            }
                            setSelectBar(allSelectBar);
                            handleSelectFilter();
                          }}
                          name={c.label}
                        />
                      );
                    } else if (c.filter.type === "date") {
                      return (
                        <div className="border-l"><DateSelector setFilteredData={(dates) => {
                          setSelectBar(allSelectBar => {
                            allSelectBar[c.id] = dates;
                            return allSelectBar
                          });
                          handleSelectFilter();
                        }}
                          name={c.label} /></div>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
          </>
        }
        buttons={
          actions && actions.length
            ? actions.concat([
              <ExportToCSV
                key="exporttocsv"
                columns={
                  typeof columns === "function"
                    ? columns(dataFiltered).filter((c) => c.exportableCSV)
                    : columns.filter((c) => c.exportableCSV)
                }
                jsonData={dataFiltered}
              />,
            ])
            : [
              <ExportToCSV
                key="exporttocsv"
                columns={
                  typeof columns === "function"
                    ? columns(dataFiltered).filter((c) => c.exportableCSV)
                    : columns.filter((c) => c.exportableCSV)
                }
                jsonData={dataFiltered}
              />,
            ]
        }
      />
      <div className="w-full overflow-x-auto">
        <table className="divide-y divide-gray-200 mb-4 text-xs 2xl:text-sm w-full h-full">
          <thead className="bg-gray-50">
            <tr>
              {columns &&
                (typeof columns === "function"
                  ? columns(dataFiltered)
                    .filter((c) => c.visible)
                    .map((column, columnKey) =>
                      column.underlineColor ? (
                        <th
                          key={columnKey}
                          scope="col"
                          className=" text-left text-gray-500 uppercase"
                        >
                          <span
                            className="inline-flex items-center py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                            style={{
                              color: "#FFFFFF",
                              backgroundColor: column.underlineColor,
                            }}
                          >
                            {column.formatHeader
                              ? handleHeaderSort(
                                column.formatHeader(column.label),
                                column.key
                              )
                              : handleHeaderSort(column.label, column.key)}
                          </span>
                        </th>
                      ) : (
                        <th
                          key={columnKey}
                          scope="col"
                          className="px-6 py-3 2xl:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.formatHeader
                            ? handleHeaderSort(
                              column.formatHeader(column.label),
                              column.key
                            )
                            : handleHeaderSort(column.label, column.key)}
                        </th>
                      )
                    )
                  : columns
                    .filter((c) => c.visible)
                    .map((column, columnKey) =>
                      column.underlineColor ? (
                        <th
                          key={columnKey}
                          scope="col"
                          className=" text-left text-gray-500 uppercase"
                        >
                          <span
                            className="inline-flex items-center py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                            style={{
                              color: "#FFFFFF",
                              backgroundColor: column.underlineColor,
                            }}
                          >
                            {column.formatHeader
                              ? handleHeaderSort(
                                column.formatHeader(column.label),
                                column.key
                              )
                              : handleHeaderSort(column.label, column.key)}
                          </span>
                        </th>
                      ) : (
                        <th
                          key={columnKey}
                          scope="col"
                          className="px-6 py-3 2xl:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {column.formatHeader
                            ? handleHeaderSort(
                              column.formatHeader(column.label),
                              column.key
                            )
                            : handleHeaderSort(column.label, column.key)}
                        </th>
                      )
                    ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {dataFiltered &&
              dataFiltered.slice(0, count).map((item, cKey) => (
                <tr key={cKey}>
                  {columns && typeof columns === "function"
                    ? columns(dataFiltered)
                      .filter((c) => c.visible)
                      .map((column, vKey) => (
                        <td
                          key={vKey}
                          className="px-6 py-3 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        >
                          {column.format
                            ? column.format(item)
                            : item[column.id]}
                        </td>
                      ))
                    : columns
                      .filter((c) => c.visible)
                      .map((column, vKey) => (
                        <td
                          key={vKey}
                          className="px-6 py-3 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        >
                          {column.format
                            ? column.format(item)
                            : item[column.id]}
                        </td>
                      ))}
                </tr>
              ))}
          </tbody>
        </table>
        {dataFiltered && dataFiltered.length > count ? (
          <div className="my-2 2xl:my-6 mb-6 flex flex-wrap justify-center">
            <Button onClick={() => setCount(count + 30)}>
              {t("Load more")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default React.memo(withTranslation()(Table));
