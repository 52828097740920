import React from "react";
import LoadingSpinner from "../Animation/LoadingSpinner";

function Button({ loading, disabled, className, children, ...newProps }) {
    return (<button
        disabled={loading || disabled}
        className={disabled ? "inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 mx-2 2xl:mx-4 border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-gray-200 opacity-50 cursor-not-allowed" :
            (className ? className :
                (loading ? "inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-green-600 hover:text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 cursor-not-allowed" : "inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-green-600 hover:text-white hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"))}
        {...newProps}
    >
        {loading ? <LoadingSpinner /> : children}
    </button>)
}

export default React.memo(Button);