import { getAccessTokenGraph } from "../../auth/verifyAuth";


async function postMail(subject, content, recipients) {
    var accessToken = await getAccessTokenGraph();

    return fetch("https://graph.microsoft.com/beta/me/sendMail", {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            message: {
                subject: subject,
                body: {
                    contentType: "html",
                    content: content
                },
                toRecipients: [
                    {
                        emailAddress: {
                            address: recipients
                        }
                    }
                ]
            },
            saveToSentItems: "true"
        }),
    })
}



export {
    postMail
}