import { TrashIcon } from "@heroicons/react/outline";
import cogoToast from "cogo-toast";
import Switch from "../../components/Inputs/Switch";
import { removeGroupMember } from "../requests/group";

export default function ColumnsGroupsMembers(t, owners, groupId, reloadMember, handleSubmitOwner, loadingOwner) {
    return [
        {
            key: "delete",
            visible: true,
            exportableCSV: false,
            label: "",
            id: "delete",
            width: 300,
            format: (row) => (
                <button className="flex-no-shrink p-2 ml-2 border-0 text-gray-600 hover:text-green "
                    onClick={() => removeGroupMember(row?.id, groupId).then(res => {
                        if (res.ok) {
                            cogoToast.success(t("User remove from group"), { position: "bottom-right" })
                            reloadMember([{ id: row?.id, deleted: true }]);
                        } else {
                            cogoToast.error(
                                t("Error on remove member") + " : " + res.status,
                                { position: "bottom-right" }
                            )
                        }
                    })}>
                    <TrashIcon width={20} />
                </button>
            ),
        },
        {
            key: "displayName",
            visible: true,
            exportableCSV: true,
            label: t("Name"),
            id: "displayName",
            width: 300,
            format: (row) => (
                <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
                    {row.displayName}
                </span>

            ),
        },
        {
            key: "mail",
            visible: true,
            exportableCSV: true,
            label: t("Office 365 ID"),
            id: "mail",
            width: 300,
            format: (row) => (
                <span>
                    {row.userPrincipalName}
                </span>

            ),
        },
        {
            key: "jobTitle",
            visible: true,
            exportableCSV: true,
            label: t("Job title"),
            id: "jobTitle",
            width: 300,
            format: (row) => (
                <span>
                    {row.jobTitle}
                </span>

            ),
        },
        {
            key: "owner",
            visible: true,
            exportableCSV: false,
            label: t("Owner"),
            id: "owner",
            width: 300,
            format: (row) => (
                <Switch key={row?.id} loading={loadingOwner} value={owners && owners.find(o => o.id === row?.id)} onClick={(value) => {
                    handleSubmitOwner(value, row)
                }} />
            ),
        }
    ];
}