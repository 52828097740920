import Checkbox from "../../components/Inputs/Checkbox";
import UpdateGroup from "../../utils/forms/Group/updateGroup";

export default function columnsGroups(t, selectedGroups, setSelectedGroups, data, reloadData, dataFiltered) {
    var col = [
        {
            key: "displayName",
            visible: true,
            label: t("Name"),
            exportableCSV: true,
            id: "displayName",
            width: 300,
            format: (row) => data ? <UpdateGroup title={t("Update school")}
                id={row?.id}
                TriggerComponent={(onClick) =>
                    <span className="text-green-500 font-weight-bolder mb-0 text-md cursor-pointer" onClick={onClick}>
                        {row.displayName}
                    </span>
                }
                reloadData={reloadData}
            /> : <span className="text-primary font-weight-bolder mb-0 text-md ">
                {row.displayName}
            </span>
        },
        {
            key: "mail",
            visible: true,
            label: t("Mail"),
            exportableCSV: true,
            id: "mail",
            width: 300,
            format: (row) => (
                <span>
                    {row?.mail}
                </span>

            ),
        },
        {
            key: "description",
            visible: true,
            label: t("Description"),
            exportableCSV: true,
            id: "description",
            width: 300,
            format: (row) => (
                <span>
                    {row.description}
                </span>

            ),
        }
    ];

    if (dataFiltered) {
        col.unshift({
            key: "checkbox",
            visible: true,
            exportableCSV: false,
            label: () => <Checkbox value={selectedGroups.length === dataFiltered.length} onClick={() => {
                if (selectedGroups.length === dataFiltered.length) {
                    setSelectedGroups([]);
                } else {
                    setSelectedGroups(dataFiltered && dataFiltered.length? dataFiltered.map(s => s.id) : [])
                }
            }} />,
            id: "checkbox",
            width: 300,
            format: (row) => (
                <Checkbox value={selectedGroups ? selectedGroups.find(s => s === row?.id) : false}
                    onClick={() => {
                        if (selectedGroups.find(s => s === row?.id)) {
                            setSelectedGroups(selectedGroups.filter(s => s !== row?.id));
                        } else {
                            selectedGroups.push(row?.id);
                            setSelectedGroups(selectedGroups)
                        }
                    }} />
            ),
        })
    }

    return col
}