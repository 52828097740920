import React from "react";
import { getUser } from "../../auth/verifyAuth";
import Aside from './Aside';
import routes from '../../utils/router/routes';

function Global({ children }) {
  const user = getUser();

  return (
    <div className="min-h-screen bg-gray-100">
      <Aside navigation={routes.filter(r => r.isMenuIcon)} user={user} />
      <main className="-mt-24 pb-8">
        <div className="flex-1 flex xl:overflow-hidden">
          {children}
        </div>
      </main>
    </div>
  )
}

export default React.memo(Global);
