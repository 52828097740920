import React, { useState } from "react";
import { withTranslation } from "react-i18next";

// requests
import { getSchoolById } from "../../requests/school";
import { patchUser, deleteUsers } from "../../requests/user";
import { resetPasswordUsers, getUsersbyIds } from "../../requests/user";

// drawer
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import Button from "../../../components/Buttons/Button";

// form
import Structure from "../../../components/Forms/Structure";
import { useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import { postMail } from "../../requests/mail";



const UpdateStudent = ({ t, id, TriggerComponent, reloadData }) => {
    const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm();
    const [school, setSchool] = useState({});
    const [error, setError] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [loadingDeletion, setLoadingDeletion] = useState(false);
    const [password, setPassword] = useState("");

    function handleOnOpen() {
        getUsersbyIds([id]).then(users => {
            if (users && users.value && users.value.length) {
                var student = users.value[0];

                getSchoolById(student.state).then(res => {
                    setSchool(res)
                })

                reset({
                    id: student.id,
                    givenName: student.givenName,
                    surname: student.surname,
                    displayName: student.displayName,
                    userPrincipalName: student.userPrincipalName,
                    officeLocation: student.officeLocation,
                    jobTitle: student.jobTitle,
                    createdDateTime: student.createdDateTime
                })
            } else {
                setError(true);
            }
        })
    }

    function onSubmit(data, reset, setOpen) {
        patchUser(data).then(res => {
            if (res.ok) {
                cogoToast.success(
                    t("Student updated"),
                    { position: "bottom-right" }
                )
                return res.json()
            } else {
                cogoToast.error(
                    t("Error on student update") + " : " + res.status,
                    { position: "bottom-right" }
                )
            }
        }).then(res => {
            if (res) {
                reset();
                setOpen(false);
                reloadData([res]);
            }
        })
    }

    function handleResetPassword() {
        setLoadingPassword(true);
        resetPasswordUsers(id).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return res;
            }
        }).then(res => {
            if (res && res.password) {
                setPassword(res.password);

                // handle mail
                var content = "Bonjour,<br /><br />Vous venez de réinitialiser le mot de passe d'un compte:<br />Login : " + getValues("userPrincipalName") + "<br />Mot de passe : ";
                content += res.password + "<br />";
                content += "<br />Cordialement,<br /><br />Votre administrateur Office 365";
                var recipients = school.administrateur;
                postMail("Réinitialisation du mot de passe de " + getValues("displayName"), content, recipients);

            } else {
                setPassword("error");
            }
            setLoadingPassword(false);
        })
    }

    function handleDelete(setOpen) {
        setLoadingDeletion(true);
        deleteUsers([id]).then(res => {
            if (res.ok) {
                cogoToast.success(
                    t("Student deleted successfully"),
                    { position: "bottom-right" }
                )
                reset();
                setOpen(false);
                reloadData([{ id: id, deleted: true }]);
            } else {
                cogoToast.error(t("Error on student deletion") + " : " + res.status,
                    { position: "bottom-right" });
            }
        })
    }



    return (
        <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
            {(setOpen) => (
                <form
                    className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
                    onSubmit={handleSubmit((data) => onSubmit(data, reset, setOpen), reset)}
                >
                    <div className="flex-1">
                        <Header title={t("Student")} setOpen={setOpen}
                            buttons={!error &&
                                <>
                                    <Button loading={loadingPassword}
                                        key="loadingPassword"
                                        disabled={loadingDeletion}
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                        onClick={() => { handleResetPassword() }}
                                    >{t("Reset password")}</Button>
                                    <Button loading={loadingDeletion}
                                        disabled={loadingPassword}
                                        key="loadingDeletion"
                                        className="ml-2 mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                        onClick={() => { handleDelete(setOpen) }}
                                    >{t("Delete")}</Button>
                                </>}
                        />
                        {error && <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                            <p className="font-bold">{t("Please wait")}</p>
                            <p>{t("An error occured. Please retry in few minutes")}</p>
                        </div>}
                        {!error && <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                            <Structure
                                id="school"
                                title={t("School")}
                                component={() => (
                                    <input
                                        name="school"
                                        id="input-school"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                                        value={school && school.intitule}
                                        readOnly
                                    />
                                )}
                            />
                            <Structure
                                id="officeLocation"
                                title={t("Classroom") + " *"}
                                error={errors.officeLocation}
                                component={() => (
                                    <input
                                        name="officeLocation"
                                        id="input-officeLocation"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                                        defaultValue={""}
                                        {...register("officeLocation", { required: true })}
                                    />
                                )}
                            />
                            <Structure
                                id="givenName"
                                error={errors.givenName}
                                title={t("Given name") + " *"}
                                component={() => (
                                    <input
                                        name="givenName"
                                        id="input-givenName"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                                        {...register("givenName", { required: true })}
                                        readOnly
                                    />
                                )}
                            />
                            <Structure
                                id="surname"
                                error={errors.surname}
                                title={t("Surname") + " *"}
                                component={() => (
                                    <input
                                        name="surname"
                                        id="input-surname"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                                        {...register("surname", { required: true })}
                                        readOnly

                                    />
                                )}
                            />
                            <Structure
                                id="displayName"
                                error={errors.displayName}
                                title={t("Display name")}
                                component={() => (
                                    <input
                                        name="displayName"
                                        id="input-displayName"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                                        {...register("displayName", { required: true })}
                                        readOnly
                                    />
                                )}
                            />
                            <Structure
                                id="userPrincipalName"
                                title={t("ID 365")}
                                error={errors.userPrincipalName}
                                component={() => (
                                    <input
                                        name="userPrincipalName"
                                        id="input-userPrincipalName"
                                        type="text"
                                        className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                                        {...register("userPrincipalName", { required: true })}
                                        readOnly
                                    />
                                )}
                            />
                            {password && password !== "" ?
                                <Structure
                                    id="password"
                                    title={t("Password")}
                                    component={() => (
                                        <input
                                            name="password"
                                            id="input-password"
                                            type="text"
                                            className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                                            value={password}
                                            readOnly
                                        />
                                    )}
                                />
                                : null}
                        </div>}
                    </div>
                    <Footer>
                        <>
                            <button
                                type="button"
                                key="button"
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                onClick={() => { reset(); setOpen(false); }}
                            >
                                {t("Cancel")}
                            </button>
                            {!error && <Button
                                key="submit"
                                type="submit"
                                disabled={loadingDeletion || loadingPassword}
                                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                                {t("Update")}
                            </Button>}
                        </>
                    </Footer>
                </form>
            )}
        </WideDrawer>
    );
};

export default React.memo(withTranslation()(UpdateStudent));
