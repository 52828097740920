// import all pages
import Main from "../../pages/Main";

/** for not Core things :
 * const ComponentName = withRouter(
    lazy(() => import('url')),
);
 */

const routes = [
    {
        path: "/",
        module: "home",
        component: Main,
        exact: true,
        isMenuIcon: false,
        name: "Home"
    }
]

export default routes;