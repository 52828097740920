import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

export default function columnsImportStudents(t, isAdmin, schools, handleRowChange) {
  var col = [
    {
      key: "status",
      visible: true,
      label: t("Status"),
      id: "status",
      width: 300,
      format: (row) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          {row.errors && row.errors.length ? <XCircleIcon color="red" width={30} /> : <CheckCircleIcon color="green" width={30} />}
        </span>
      ),
    },
    {
      key: "type",
      visible: true,
      label: t("Type"),
      id: "type",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          {row.type}
        </span>
      ),
    },
    {
      key: "givenName",
      visible: true,
      label: t("Given name"),
      id: "givenName",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <input
            name="givenName"
            id="input-givenName"
            type="text"
            required
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            defaultValue={row.givenName}
            onBlur={(e) => {
              var value = e.target.value;
              row.givenName = value;
              handleRowChange(row, key);
            }}
          />
        </span>
      ),
    },
    {
      key: "surname",
      visible: true,
      label: t("Surname"),
      id: "surname",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <input
            name="surname"
            id="input-surname"
            type="text"
            required
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            defaultValue={row.surname}
            onBlur={(e) => {
              var value = e.target.value;
              row.surname = value;
              handleRowChange(row, key);
            }}
          />
        </span>
      ),
    },
    {
      key: "displayName",
      visible: true,
      label: t("Display name"),
      id: "displayName",
      width: 300,
      format: (row) => (
        <span>{row.displayName}</span>
      ),
    },
    {
      key: "ID365",
      visible: true,
      label: t("ID 365"),
      id: "ID365",
      width: 300,
      format: (row) =>
          <span>{row.userPrincipalName}</span>
    },
    {
      key: "officeLocation",
      visible: true,
      label: t("Classroom"),
      id: "officeLocation",
      width: 300,
      format: (row, key) => (
        <input
          name="officeLocation"
          id="input-officeLocation"
          type="text"
          required
          className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
          defaultValue={row.officeLocation}
          onBlur={(e) => {
            var value = e.target.value;
            row.officeLocation = value;
            handleRowChange(row, key);
          }}
        />
      ),
    },
  ];
  if (isAdmin) {
    col.push({
      key: "school",
      visible: true,
      label: t("School"),
      id: "school",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <select
            name="school"
            id="input-school"
            type="text"
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            required
            value={row.state}
            onChange={(e) => {
              var value = e.target.value;
              row.state = value;
              handleRowChange(row, key);
            }}
          >
            <option />
            {schools &&
              schools.map((sch, key) => (
                <option key={key} value={sch.uai}>
                  {sch.intitule}
                </option>
              ))}
          </select>
        </span>
      ),
    });
  }
  col.push({
    key: "errors",
    visible: true,
    label: t("Errors"),
    id: "errors",
    width: 300,
    format: (row) => (
      <ul>
        {row.errors && row.errors.length
          ? row.errors.map((error, key) => <li key={key}>{error}</li>)
          : null}
      </ul>
    ),
  });

  return col;
}
