import Checkbox from "../../components/Inputs/Checkbox";
import UpdateStaff from "../forms/Users/updateStaff";

export default function columnsStaffs(t, selectedStaffs, setSelectedStaffs, data, reloadData, admin, dataFiltered) {
    var col = [
        {
            key: "givenName",
            visible: false,
            label: t("Given name"),
            id: "givenName",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.givenName}</span>,
        },
        {
            key: "surname",
            visible: false,
            label: t("Surname"),
            id: "surname",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.surname}</span>,
        },
        {
            key: "displayName",
            visible: true,
            label: t("Name"),
            id: "displayName",
            exportableCSV: true,
            width: 300,
            format: (row) => data ?
                <UpdateStaff id={row?.id}
                    TriggerComponent={(onClick) =>
                        <span className="text-green-500 font-weight-bolder mb-0 text-md cursor-pointer" onClick={onClick}>
                            {row.displayName}
                        </span>}
                    reloadData={reloadData} />
                : <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
                    {row.displayName}
                </span>
        },
        {
            key: "jobTitle",
            visible: true,
            exportableCSV: true,
            label: t("Job title"),
            id: "jobTitle",
            width: 300,
            format: (row) => <span>{row.jobTitle}</span>,
            filter:{
                type:"select"
            }
        },
        {
            key: "userPrincipalName",
            visible: true,
            exportableCSV: true,
            label: t("ID 365"),
            id: "userPrincipalName",
            width: 300,
            format: (row) => <span>{row.userPrincipalName}</span>,
        },
        {
            key: "createdDateTime",
            visible: true,
            exportableCSV: true,
            label: t("Creation date"),
            id: "createdDateTime",
            width: 300,
            format: (row) => <span>{ (new Date(row.createdDateTime)).getDate() + "/" + ((new Date(row.createdDateTime)).getMonth() + 1) + "/" + (new Date(row.createdDateTime)).getFullYear()}</span>,
        },
    ];

    if (dataFiltered) {
        col.unshift({
            key: "checkbox",
            visible: true,
            exportableCSV: false,
            label: () => <Checkbox value={selectedStaffs.length === dataFiltered.length} onClick={() => {
                if (selectedStaffs.length === dataFiltered.length) {
                    setSelectedStaffs([]);
                } else {
                    setSelectedStaffs(dataFiltered ? dataFiltered.map(s => s.id) : [])
                }
            }} />,
            id: "checkbox",
            width: 300,
            format: (row) => (
                <Checkbox
                    value={selectedStaffs ? selectedStaffs.find(s => s === row?.id) : false}
                    onClick={() => {
                        if (selectedStaffs && selectedStaffs.find(s => s === row?.id)) {
                            setSelectedStaffs(selectedStaffs.filter(s => s !== row?.id));
                        } else {
                            selectedStaffs.push(row?.id);
                            setSelectedStaffs(selectedStaffs);
                        }
                    }} />
            ),
        })
    }

    if (admin) {
        col.push({
            key: "school",
            visible: false,
            label: t("School"),
            id: "school",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.school}</span>,
        });
        col.push({
            key: "state",
            visible: false,
            label: t("UAI"),
            id: "state",
            exportableCSV: true,
            width: 300,
            format: (row) => <span>{row.state}</span>,
        })
    }

    return col
}