import React from "react";

function TabsUnderline({ tabs, selectedTab, setSelectedTab, actions }) {
    return (<div>
        <div className="sm:hidden">
            <select
                id="tabs"
                name="tabs"
                className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-md cursor-pointer"
                defaultValue={tabs.find((tab) => tab.id === selectedTab) ? tabs.find((tab) => tab.id === selectedTab).id : null}
                onClick={(ev) => { setSelectedTab(ev.target.value) }}
            >
                {tabs.map((tab) => (
                    <option key={tab.id} value={tab.id}>{tab.name}</option>
                ))}
            </select>
            {actions ? <div className="sm:mt-0 sm:ml-4 mr-4">
                {actions && actions.length ? actions.map(a => a) : actions}
            </div> : null}
        </div>
        <div className="hidden sm:block">
            <div className="border-b border-gray-200 sm:flex sm:items-center sm:justify-between sm:flex">
                <nav className="-mb-px flex space-x-8 pl-5" aria-label="Tabs">
                    {tabs.map((tab) => (
                        <div
                            key={tab.id}
                            onClick={() => setSelectedTab(tab.id)}
                            className={
                                tab.id === selectedTab
                                    ? 'border-green-500 text-green-600 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                            }
                            aria-current={selectedTab}
                        >
                            {tab.name}
                        </div>
                    ))}
                </nav>
                {actions && <div className="flex sm:mt-0 sm:ml-4 mr-4">
                {actions && actions.length ? actions.map(a => a) : actions}
                </div>}
            </div>
        </div>
    </div>);
}

export default React.memo(TabsUnderline);