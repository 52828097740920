import React, { Fragment } from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { logout } from "../../../auth/verifyAuth";

function Menu({ mobileMenuOpen, navigation, user, t, history }) {
  return (
    <>
      <div className="absolute right-0 flex-shrink-0 lg:hidden">
        {/* Mobile menu button */}
        <Popover.Button className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-green-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
          <span className="sr-only">Open main menu</span>
          {mobileMenuOpen ? (
            <XIcon className="block h-6 w-6" aria-hidden="true" />
          ) : (
            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
          )}
        </Popover.Button>
      </div>
      <Transition.Root as={Fragment}>
        <div className="lg:hidden">
          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-150 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-150 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                <div className="pt-3 pb-2">
                  <div className="flex items-center justify-between px-4">
                    <div>
                    CNEAP - Administration déléguée
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        onClick={() => {
                          history.push(item.path);
                        }}
                        className="group p-2 rounded-md flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                      >
                        <item.logo
                          className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                        {t(item.name)}
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" pl-4 pt-4 pb-4">
                  <div className="flex items-center" onClick={() => { logout() }}>
                    <div>
                      <img
                        className="inline-block h-10 w-10 rounded-full"
                        src={user ? user.imageUrl : null}
                        alt=""
                      />
                    </div>
                    <div className="ml-3">
                      <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                        {user ? user.name : null}
                      </p>
                      <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                        {t("Account Settings")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition.Child>
        </div>
      </Transition.Root>
    </>
  );
}

export default React.memo(withTranslation()(withRouter(Menu)));
