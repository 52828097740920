import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import Table from "../../../components/Tables/Table";
import columnsImportGroups from "../../tables/ImportGroup";
import handleCSVImport from "../../functions/handleCSVImport";
import {
  getStaffs,
  isUserAdmin
} from "../../requests/user";
import Structure from "../../../components/Forms/Structure";
import { getSchool, getSchools } from "../../requests/school";
import cogoToast from "cogo-toast";
import Button from "../../../components/Buttons/Button";
import { CSVLink } from "react-csv";
import { divideChunks } from "../../functions/divideChunks";
import { postGroups } from "../../requests/group";

const dataExample = [
  {
    name: "Teams classe test",
    description: "Ceci est un exemple de description pour l'import en masse des teams",
    owner: "ludovic.peugeot@cneap.fr",
    modele: "1",
    // members: "noemie.ines@test.cneap.fr;tess.jouliane@test.cneap.fr;marie.julien@test.cneap.fr",
    school: "00000000",
  },
  {
    name: "Teams classe test 2",
    description: "Ceci est un exemple de description pour l'import en masse des teams",
    owner: "test.test@test.cneap.fr",
    modele: "2",
    // members: "noemie.ines@test.cneap.fr;tess.jouliane@test.cneap.fr;marie.julien@test.cneap.fr",
    school: "00000000",
  },
];

const columnAdminExample = [
  {
    label: "Nom",
    key: "name",
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Propriétaire",
    key: "owner",
  },
  {
    label: "Modèle",
    key: "modele",
  },
  // {
  //   label: "Membres",
  //   key: "members",
  // },
  {
    label: "Code Etablissement",
    key: "school",
  },
];

const columnStaffExample = [
  {
    label: "Nom",
    key: "name",
  },
  {
    label: "Description",
    key: "description",
  },
  {
    label: "Propriétaire",
    key: "owner",
  },
  {
    label: "Modèle",
    key: "modele",
  },
  // {
  //   label: "Membres",
  //   key: "members",
  // },
];

const ImportGroup = ({ t, TriggerComponent, reloadData }) => {
  const [admin, setAdmin] = useState(false);
  const [groups, setGroups] = useState([]);
  const [school, setSchool] = useState({});
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffs, setStaffs] = useState([])

  function handleOnOpen() {
    isUserAdmin().then((isAdmin) => {
      setAdmin(isAdmin);
      if (isAdmin) {
        getSchools().then((schools) => {
          setSchools(schools);
        });
      } else {
        getSchool().then((sch) => {
          setSchool(sch);
        });
      }
    });
  }

  function handleImportCSVGroup(e) {
    handleCSVImport(e, (data) => {
      var dataFormated = []
      getStaffs(admin ? data?.map(d => d["Code Etablissement"]).filter((value, index, array) => array.indexOf(value) === index) : [school.uai], () => { }, true).then(staffsAdmin => {
        setStaffs(staffsAdmin)
        data.forEach((d) => {
          d.errors = [];
          d.name = d.Nom;
          d.description = d.Description;
          d.owners = (d.Propriétaire || d.Proprietaire).split(";");
          if (d.owners) {
            var st = staffsAdmin?.find(s => s.userPrincipalName === d.owners[0])
            d.owner = st?.id
            if (d.owner) {
              d["owners@odata.bind"] = ["https://graph.microsoft.com/beta/users('" + st.id + "')"];
            }
          }
          if (d.Modèle || d.Modele) {
            var m = d.Modèle || d.Modele
            var template = ""
            switch (m) {
              case 1:
              case "1":
              case "Classe":
              case "educationClass":
                template = "educationClass"
                break;
              case 2:
              case "2":
              case "Personnel":
              case "educationStaff":
                template = "educationStaff"
                break;
              case 3:
              case "3":
              case "Autre":
              case "standard":
                template = "standard"
                break;
              default:
                break;
            }
            d['template'] = template
            d["template@odata.bind"] = "https://graph.microsoft.com/beta/teamsTemplates('" + template + "')";
          }
          d.displayName = d.name + " - " + school.ville;
          d.members = d.Members || d.Membres;

          if (!d.name) {
            d.errors.push(t("Name is required"));
          }

          if (!d.owner) {
            d.errors.push(t("At least one owner is required"));
          }

          if (!d["template@odata.bind"]) {
            d.errors.push(t("Modele is required"));
          }

          if (admin && schools && schools.length) {
            var schoolAd = schools.find(
              (sch) => sch.uai === d["Code Etablissement"]
            );
            if (schoolAd && schoolAd.intitule) {
              d.school = schoolAd.intitule;
              d.state = d["Code Etablissement"];
              d.displayName = d.name + " - " + schoolAd.ville;
            } else {
              d.errors.push(
                t("School not find") + " : '" + d["Code Etablissement"] + "'"
              );
            }
          }
          dataFormated.push(d)
        });
        setGroups(dataFormated);
      })
    })
  }

  function handleAdminChangeSchool(g) {
    if (admin) {
      var school = g.map(g => g.state).filter((value, index, array) => array.indexOf(value) === index)
      getStaffs(school, () => { }, true).then(res => {
        setStaffs(res)
      })
    }
  }

  function verifyRow(row) {
    row.errors = [];
    row.name = row?.Nom;
    row.description = row?.Description;
    if (row.owner) {
      row["owners@odata.bind"] = ["https://graph.microsoft.com/beta/users('" + row.owner + "')"];
    }
    if (row.Modèle || row.Modele) {
      var m = row?.Modèle || row.Modele
      var template = ""
      switch (m) {
        case 1:
        case "1":
        case "Classe":
        case "educationClass":
          template = "educationClass"
          break;
        case 2:
        case "2":
        case "Personnel":
        case "educationStaff":
          template = "educationStaff"
          break;
        case 3:
        case "3":
        case "Autre":
        case "standard":
          template = "standard"
          break;
        default:
          break;
      }
      row['template'] = template
      row["template@odata.bind"] = "https://graph.microsoft.com/beta/teamsTemplates('" + template + "')";
    }
    row.displayName = row?.name + " - " + school.ville;
    row.members = row?.Members || row.Membres;

    if (!row.name) {
      row.errors.push(t("Name is required"));
    }

    if (!row.owner) {
      row.errors.push(t("Owner is required"));
    }

    if (!row["template@odata.bind"]) {
      row.errors.push(t("Modele is required"));
    }

    if (admin && schools && schools.length) {
      var schoolAd = schools.find(
        (sch) => sch.uai === row["Code Etablissement"]
      );
      if (schoolAd && schoolAd.intitule) {
        row.school = schoolAd.intitule;
        row.state = row["Code Etablissement"];
        row.displayName = row?.name + " - " + schoolAd.ville;
      } else {
        row.errors.push(
          t("School not find") + " : '" + row["Code Etablissement"] + "'"
        );
      }
    }
    return row
  }

  function replaceRowInList(row, key) {
    var groupsUpdated = groups;
    Promise.all([setGroups([])]).then(
      () => {
        groupsUpdated[key] = row;
        if (key === "state") {
          handleAdminChangeSchool(groupsUpdated)
        }
        setGroups(groupsUpdated);
      });

  }

  function handleImport(setOpen) {
    setLoading(true);
    var promises = [];
    var promisesPost = [];
    var groupsPostChunk = divideChunks(groups, 50);
    groupsPostChunk.forEach((uP) => {
      promisesPost.push(
        postGroups(uP)
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              cogoToast.error(
                t("Error on create groups") + " : " + res.status,
                { position: "bottom-right" }
              );
            }
          })
          .then((res) => {
            if (res && res.groups) {
              return res;
            } else {
              return [];
            }
          })
      );
    });
    promises.push(
      Promise.all(promisesPost).then((groupsList) => {
        var resultPost = { groups: [], errors: [], amountErrors: 0 };

        groupsList.forEach((u) => {
          resultPost.groups = resultPost.groups.concat(u.groups);
          resultPost.errors = resultPost.errors.concat(u.errors);
          resultPost.amountErrors += u.amountErrors;
        });

        if (resultPost.groups.length > 0) {
          cogoToast.success(
            t("x groups created", { x: resultPost.groups.length }),
            { position: "bottom-right" }
          );
        }

        return resultPost;
      })
    );

    Promise.all(promises)
      .then(([groupsPost]) => {
        if (groupsPost && groupsPost.groups && groupsPost.groups.length) {
          reloadData(groupsPost.groups);
        } else {
          reloadData([]);
        }

        setLoading(false);
        setGroups([]);
        setOpen(false);
      })
      .catch((errors) => {
        setLoading(false);
        setGroups([]);
        cogoToast.error(t("An error occured") + " : " + errors, {
          position: "bottom-right",
        });
      });
  }

  return (
    <WideDrawer
      handleOnOpen={handleOnOpen}
      TriggerComponent={(onClick) => TriggerComponent(onClick, loading)}
    >
      {(setOpen) => (
        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
          <div className="flex-1">
            <Header title={t("Import Group")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="sample"
                title={t("Example")}
                description={t("Concernant le champ 'modele', 1 correspond au template Teams Classe, 2 à Personnel et 3 à Autre")}
                component={() => (
                  <>
                    <CSVLink
                      data={dataExample}
                      headers={admin ? columnAdminExample : columnStaffExample}
                      separator={";"}
                      className=""
                      filename="ImportExemple.csv"
                    >
                      <Button>{t("Download example")}</Button>
                    </CSVLink>
                  </>
                )}
              />
              <Structure
                id="file"
                title={t("File") + " *"}
                component={() => (
                  <input
                    type="file"
                    name="uploadFile"
                    id="uploadfile"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={handleImportCSVGroup}
                  />
                )}
              />
              {groups && groups.length ? (
                <Table
                  key="groupsToImport"
                  data={groups}
                  columns={columnsImportGroups(
                    t,
                    admin,
                    staffs,
                    schools,
                    (row, key) => {
                      replaceRowInList(verifyRow(row, key), key)
                    }
                  )}
                />
              ) : null}
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => setOpen(false)}
              >
                {t("Cancel")}
              </button>
              <Button
                className={
                  groups &&
                    groups.length &&
                    !groups.find((user) => user.errors && user.errors.length)
                    ? "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 cursor-not-allowed"
                }
                disabled={
                  !groups ||
                  !groups.length ||
                  groups.find((user) => user.errors && user.errors.length)
                }
                loading={loading}
                onClick={() => handleImport(setOpen)}
              >
                {t("Import")}
              </Button>
            </>
          </Footer>
        </div>
      )}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(ImportGroup));
