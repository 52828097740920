import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import getContentFiltered from "../../../utils/functions/filteredData";

function SearchBar({ data, setFilteredData, t }) {
    const [visible, setVisible] = useState(false);

    function onInputChange(event) {
        var value = event.target.value;
        var dataFiltered = getContentFiltered(data, value);
        setFilteredData(dataFiltered);
    }

    return visible ?
        <div className="flex">
            <div className="flex-1 px-2 flex justify-center lg:ml-1 my-1 lg:justify-end">
                <div className="max-w-xs w-full">
                    <label htmlFor="search" className="sr-only">Search</label>
                    <div className="relative text-light-green-100 focus-within:text-gray-400">
                        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <input
                            onChange={onInputChange}
                            type="text"
                            id="search" name="search"
                            className="block w-full bg-light-gray-700 bg-opacity-50 py-1 2xl:py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 placeholder-light-green-100 focus:outline-none focus:bg-white focus:ring-green-700 focus:border-green-500 focus:placeholder-gray-500 focus:text-gray-900 text-xs 2xl:text-sm" placeholder={t("Search")} />
                    </div>
                </div>
            </div>
            <button onClick={() => setVisible(!visible)} className="w-4 2xl:w-5 mr-3" style={{ color: "#4B5563" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                    aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                    <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M41.999 20.002l-22 22m22 0L20 20" strokeLinejoin="round" strokeLinecap="round"></path>
                </svg>
            </button>
        </div>
        :
        <div className="flex">
            <button onClick={() => setVisible(!visible)} className="w-4 2xl:w-5 text-gray-600" style={{ color: "#4B5563" }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                    <path data-name="layer1" d="M61.122 56.88L41.54 37.284l-.037.037a22.01 22.01 0 1 0-4.173 4.175l-.031.031L56.878 61.12a3 3 0 0 0 4.244-4.242zM24 40a16 16 0 1 1 16-16 16.002 16.002 0 0 1-16 16z" fill="#202020"></path>
                </svg>
            </button>
        </div>
}

export default React.memo(withTranslation()(SearchBar));
