import { getUserByUserPrincipalName } from "../requests/user"

export default function generateO356ID(givenName, surname, domaine) {
  var userPrincipalName = "";
  givenName = givenName ? givenName : "";
  surname = surname ? surname : "";
  userPrincipalName =
    givenName
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(" ", "")
      .split(" ")
      .join("")
      .split("'")
      .join("") +
    "." +
    surname
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(" ", "")
      .split(" ")
      .join("")
      .split("'")
      .join("");
  return getUserByUserPrincipalName(userPrincipalName + "@" + domaine).then(res => {
    if (res.ok) {
      return getAvailableAdress(userPrincipalName, domaine, 1)
    } else {
      return userPrincipalName + "@" + domaine;
    }
  });
}

function getAvailableAdress(userPrincipalName, domaine, number) {
  return getUserByUserPrincipalName(userPrincipalName + number + "@" + domaine).then((res) => {
    if (res.ok) {
      return getAvailableAdress(userPrincipalName, domaine, number + 1)
    } else {
      return userPrincipalName + number + "@" + domaine;
    }
  })
}
