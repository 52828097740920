import React, { useState } from "react";
import { withTranslation } from "react-i18next";

// requests
import generateDisplayName from "../../functions/generateDisplayName";
import generateO356ID from "../../functions/generateO365ID";
import generatePassword from "../../functions/generatePassword";
import generateMailNickname from "../../functions/generateMailNickname";
import { getSchool, getSchools } from "../../requests/school";
import { isUserAdmin } from "../../requests/user";
import { postUser } from "../../requests/user";

// drawer
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import Button from "../../../components/Buttons/Button";

// form
import Structure from "../../../components/Forms/Structure";
import { Controller, useForm } from "react-hook-form";
import cogoToast from "cogo-toast";
import { postMail } from "../../requests/mail";

const CreateStaff = ({ t, TriggerComponent, reloadData }) => {
  const { register, handleSubmit, reset, control, formState: { errors }, getValues, ...api } = useForm();
  const [admin, setAdmin] = useState(false);
  const [schools, setSchools] = useState([]);
  const [school, setSchool] = useState({});

  function handleOnOpen() {
    var tempPassword = generatePassword(8);
    reset({ tempPassword: tempPassword });

    isUserAdmin().then(isAdmin => {
      if (isAdmin) {
        setAdmin(true);
        getSchools().then(schs => {
          setSchools(schs);
        });
      } else {
        getSchool().then(sch => {
          setSchool(sch);
          reset({
            state: sch.uai
          })
          setSchools([sch]);
        });
      }
    })
  }

  function onSubmit(data, reset, setOpen) {
    data.passwordProfile = {
      forceChangePasswordNextSignIn: "true",
      password: data.tempPassword
    }
    delete data.password;

    data.mailNickname = generateMailNickname(data.givenName, data.surname);

    postUser(data).then(res => {
      if (res.ok) {
        return res.json();
      } else {
        cogoToast.error(
          t("Error on create staff") + " : " + res.status,
          { position: "bottom-right" }
        )
      }
    }).then(res => {
      if (res && res.users) {
        cogoToast.success(t("Staff created"),
          { position: "bottom-right" })

        if (res.users[0] && res.users[0].passwordProfile) {
          //handleMail
          var content = "Bonjour,<br /><br />Voici le compte que vous venez de créer :<br />";
          content += "Login : '" + res.users[0].userPrincipalName + "'<br />Mot de passe : '" + res.users[0].passwordProfile.password + "'<br />";
          content += "<br />Cordialement,<br /><br />Votre administrateur Office 365"
          var recipients = school.administrateur;

          postMail("Création du compte établissement de " + res.users[0].displayName, content, recipients);
        }
        reloadData(res.users)
        reset();
        setOpen(false);
      }
    })
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
      {(setOpen) => (
        <form
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
          onSubmit={handleSubmit((data) => onSubmit(data, reset, setOpen), reset)}
        >
          <div className="flex-1">
            <Header title={t("Create staff")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="state"
                title={t("School") + " *"}
                error={errors.state}
                component={() => (
                  <Controller
                    name="state"
                    id="input-state"
                    control={control}
                    render={({ field }) => (
                      <select
                        name="state"
                        id="input-state"
                        type="text"
                        className={admin ? "block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2" : "block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"}
                        {...field}
                        required
                        disabled={!admin}
                        onChange={(e) => {
                          var value = e.target.value;
                          var sch = {};
                          if (schools.find(s => s.uai === value)) {
                            setSchool(schools.find(s => s.uai === value));
                            sch = schools.find(s => s.uai === value).domaine;
                          }
                          var givenName = getValues("givenName");
                          var surname = getValues("surname");

                          if (sch && sch.uai) {
                            generateO356ID(givenName, surname, sch).then(res => {
                              api.setValue("userPrincipalName", res);
                            })
                          }

                          field.onChange(value);
                        }}
                      >
                        {admin && <option />}
                        {schools && schools.length ? schools.map(st => <option key={st.uai} value={st.uai}>{st.intitule}</option>) : null}
                      </select>
                    )}
                  />
                )}
              />
              <Structure
                id="jobTitle"
                title={t("Role") + " *"}
                component={() => (
                  <select
                    name="jobTitle"
                    id="input-jobTitle"
                    type="text"
                    required
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    {...register("jobTitle", { required: true })}
                  >
                    <option />
                    <option>Président</option>
                    <option>Administrateur</option>
                    <option>Chef d'établissement</option>
                    <option>Directeur Adjoint</option>
                    <option>Personnel Administratif</option>
                    <option>Personnel Technique</option>
                    <option>Enseignant</option>
                    <option>Formateur</option>
                    <option>PVS</option>
                    <option>Documentaliste</option>
                  </select>
                )}
              />
              <Structure
                id="givenName"
                title={t("Given name") + " *"}
                component={() => (
                  <Controller
                    name="givenName"
                    id="input-givenName"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...field}
                        required
                        onChange={(e) => {
                          var value = e.target.value;
                          var surname = getValues("surname");
                          var sch = getValues("state");
                          if (schools.find(s => s.uai === getValues("state"))) {
                            sch = schools.find(s => s.uai === getValues("state")).domaine;
                          }
                          api.setValue("displayName", generateDisplayName(value, surname));
                          if (sch) {
                            api.setValue("userPrincipalName", generateO356ID(value, surname, sch));
                          }
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                )}
              />
              <Structure
                id="surname"
                title={t("Surname") + " *"}
                component={() => (
                  <Controller
                    name="surname"
                    id="input-surname"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...field}
                        required
                        onChange={(e) => {
                          var value = e.target.value;
                          var givenName = getValues("givenName");
                          var sch = getValues("state");
                          if (schools.find(s => s.uai === getValues("state"))) {
                            sch = schools.find(s => s.uai === getValues("state")).domaine;
                          }
                          api.setValue("displayName", generateDisplayName(givenName, value));
                          if (sch) {
                            generateO356ID(givenName, value, sch).then(res => {
                              api.setValue("userPrincipalName", res);
                            })
                          }

                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                )}
              />
              <Structure
                id="displayName"
                title={t("Display name")}
                component={() => (
                  <input
                    name="displayName"
                    id="input-displayName"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                    {...register("displayName", { required: true })}
                    readOnly
                  />
                )}
              />
              <Structure
                id="userPrincipalName"
                title={t("ID 365")}
                component={() => (
                  <input
                    name="userPrincipalName"
                    id="input-userPrincipalName"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                    {...register("userPrincipalName", { required: true })}
                    readOnly
                  />
                )}
              />
              <Structure
                id="tempPassword"
                title={t("Temporary password")}
                component={() => (
                  <input
                    name="tempPassword"
                    id="input-tempPassword"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border-gray-300 border rounded-md p-2 bg-gray-200"
                    {...register("tempPassword", { required: true })}
                    readOnly
                  />
                )}
              />
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => { reset(); setOpen(false); }}
              >
                {t("Cancel")}
              </button>
              <Button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {t("Submit")}
              </Button>
            </>
          </Footer>
        </form>
      )}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(CreateStaff));
