import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import { deleteUsers, getUsersbyIds } from "../../requests/user";
import Table from "../../../components/Tables/Table";
import columnsStaffs from "../../tables/Staffs";
import cogoToast from "cogo-toast";
import Button from "../../../components/Buttons/Button";

const DeleteUsers = ({ t, TriggerComponent, usersId, reloadData }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleOnOpen() {
    getUsersbyIds(usersId).then(users => {
      setUsers(users.value)
    })
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={(onClick) => TriggerComponent(onClick, loading)}>
      {(setOpen) => (
        <div
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
        >
          <div className="flex-1">
            <Header title={t("Delete users")} description={t("Are you sure to delete all the users listed below ?")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Table key="usersToDelete" data={users} columns={columnsStaffs(t)} />
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => setOpen(false)}
              >
                {t("Cancel")}
              </button>
              <Button
                loading={loading}
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => {
                  setLoading(true);
                  deleteUsers(users.map(u => u.id)).then(res => {
                    if (res.ok) {
                      cogoToast.success(t("users deleted"),
                        { position: "bottom-right" })
                      var deletedUsers = [];
                      users.forEach(u => {
                        deletedUsers.push({ id: u.id, deleted: true })
                      })
                      setUsers([]);
                      reloadData(deletedUsers);
                      setLoading(false);
                      setOpen(false);
                    } else {
                      cogoToast.error(
                        t("An error occured") + " : " + res.status,
                        { position: "bottom-right" }
                      );
                      setLoading(false);
                      setUsers([]);
                    }
                  }).catch(error => {
                    cogoToast.error(
                      t("An error occured") + " : " + error,
                      { position: "bottom-right" }
                    );
                    setLoading(false);
                    setUsers([]);
                  });
                }}
              >
                {t("Delete")}
              </Button>
            </>
          </Footer>
        </div>
      )}
    </WideDrawer>

  );
};

export default React.memo(withTranslation()(DeleteUsers));
