import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";

async function isUserAdmin() {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/admin", {
    method: "GET",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  });
}

async function getStudents(schoolIds, setAmount, allData) {
  var accessToken = await getAccessTokenGraph();
  var request =
    "https://graph.microsoft.com/beta/users?&$filter=jobTitle eq 'Eleve'";
  if (schoolIds && schoolIds.length) {
    request += " and state in (";
    schoolIds.forEach((id, index) => {
      if (index + 1 !== schoolIds.length) request += "'" + id + "',";
      else request += "'" + id + "'";
    });
    request += ")";
  }
  request +=
    "&$top=999&$select=displayName,givenName,surname,userPrincipalName,id,createdDateTime,officeLocation,state,jobTitle&$count=true";
  return fetch(request, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      if (res) {
        if (setAmount) {
          setAmount(res["@odata.count"]);
        }
        if (allData) {
          if (res["@odata.nextLink"]) {
            return handleNextLink(
              res["@odata.nextLink"],
              accessToken,
              res.value
            );
          } else {
            var result = res.value;
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
          }
        }
      }
    });
}

async function getStudentsAmount(schoolIds) {
  var accessToken = await getAccessTokenGraph();
  var request =
    "https://graph.microsoft.com/beta/users?&$filter=jobTitle eq 'Eleve'";
  if (schoolIds && schoolIds.length) {
    request += " and state in (";
    schoolIds.forEach((id, index) => {
      if (index + 1 !== schoolIds.length) request += "'" + id + "',";
      else request += "'" + id + "'";
    });
    request += ")";
  }
  request +=
    "&$top=999&$select=displayName,givenName,surname,userPrincipalName,id,createdDateTime,officeLocation,state,jobTitle&$count=true";
  return fetch(request, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      if (res) {
        return res["@odata.count"];
      } else {
        return 0;
      }
    });
}

async function getUserByUserPrincipalName(userPrincipalName) {
  var accessToken = await getAccessTokenGraph();
  var request = "https://graph.microsoft.com/beta/users/" + userPrincipalName;
  return fetch(request, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getStaffs(schoolIds, setAmount, allData) {
  var accessToken = await getAccessTokenGraph();
  var request = "https://graph.microsoft.com/beta/users?&$filter=";
  if (schoolIds && schoolIds.length) {
    request += "state in (";
    schoolIds.forEach((id, index) => {
      if (index + 1 !== schoolIds.length) request += "'" + id + "',";
      else request += "'" + id + "'";
    });
    request += ")";
  }
  request +=
    "&$top=999&$select=displayName,givenName,surname,userPrincipalName,id,createdDateTime,officeLocation,state,jobTitle&$count=true";
  return fetch(request, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      if (res) {
        if (setAmount) {
          getStudentsAmount(schoolIds).then((amountStudent) => {
            setAmount(res["@odata.count"] - amountStudent);
          });
        }
        if (allData) {
          if (res["@odata.nextLink"]) {
            return handleNextLinkStaff(
              res["@odata.nextLink"],
              accessToken,
              res.value.filter((u) => u.jobTitle !== "Eleve")
            );
          } else {
            var result = res.value.filter((u) => u.jobTitle !== "Eleve");
            result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
            return result;
          }
        }
      }
    });
}

async function handleNextLink(nextLink, accessToken, data) {
  return fetch(nextLink, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      var dataUpdated = data;
      if (res && res.value) {
        dataUpdated = dataUpdated.concat(res.value);
      }
      if (res && res["@odata.nextLink"]) {
        return handleNextLink(res["@odata.nextLink"], accessToken, dataUpdated);
      } else {
        var result = dataUpdated;
        result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
        return result;
      }
    });
}

async function handleNextLinkStaff(nextLink, accessToken, data) {
  return fetch(nextLink, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      var dataUpdated = data;
      if (res && res.value) {
        dataUpdated = dataUpdated.concat(res.value);
      }
      if (res && res["@odata.nextLink"]) {
        return handleNextLink(
          res["@odata.nextLink"],
          accessToken,
          dataUpdated.filter((u) => u.jobTitle !== "Eleve")
        );
      } else {
        var result = dataUpdated.filter((u) => u.jobTitle !== "Eleve");
        result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
        return result;
      }
    });
}

async function getSchoolUsers(uai) {
  var accessToken = await getAccessTokenGraph();
  return fetch(
    "https://graph.microsoft.com/beta/users?&$top=999&$filter=state eq '" +
      uai +
      "'&$select=displayName,givenName,surname,userPrincipalName,id,createdDateTime,officeLocation,state,jobTitle&$count=true",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ConsistencyLevel: "eventual",
      },
    }
  )
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      if (res) {
        if (res["@odata.nextLink"]) {
          return handleNextLink(res["@odata.nextLink"], accessToken, res.value);
        } else {
          var result = res.value;
          result.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
          return result;
        }
      }
    });
}

async function getMe() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/beta/me", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then(res => {
    if (res.ok) {
        return res.json();
    }
});
}

async function getUserPhoto() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/beta/me/photo", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}

async function getUsersbyIds(usersId) {
  var accessToken = await getAccessTokenGraph();

  if (usersId && usersId.length) {
    var query = "https://graph.microsoft.com/v1.0/users?$filter=id in (";
    usersId.forEach((id, index) => {
      if (index + 1 !== usersId.length) query += "'" + id + "',";
      else query += "'" + id + "'";
    });
    query +=
      ")&$select=displayName,givenName,surname,userPrincipalName,id,createdDateTime,officeLocation,state,jobTitle, proxyAddresses";

    return fetch(query + "&$count=true", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ConsistencyLevel: "eventual",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return { value: [] };
        }
      })
      .then((res) => {
        if (res) {
          res.value.sort((a, b) => (a.displayName < b.displayName ? -1 : a.displayName > b.displayName ? 1 : 0));
          return res;
        } else {
          return { value: [] };
        }
      });
  } else {
    return { value: [] };
  }
}

async function postUser(user) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/user", {
    method: "POST",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users: [user] }),
  });
}

async function postUsers(users) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/user", {
    method: "POST",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users: users }),
  });
}

async function patchUser(user) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/user", {
    method: "PATCH",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users: [user] }),
  });
}

async function patchUsers(users) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/user", {
    method: "PATCH",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users: users }),
  });
}

async function deleteUsers(usersId) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/user", {
    method: "DELETE",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ids: usersId }),
  });
}

async function resetPasswordUsers(id) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/userpassword/" + id, {
    method: "PATCH",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  });
}

export {
  getSchoolUsers,
  getUserPhoto,
  deleteUsers,
  getUsersbyIds,
  resetPasswordUsers,
  isUserAdmin,
  getStudents,
  getStaffs,
  patchUser,
  postUser,
  postUsers,
  getUserByUserPrincipalName,
  patchUsers,
  getMe
};
