import React, { useState } from "react";
import { withTranslation } from "react-i18next";

// requests
import { getUser } from "../../../auth/verifyAuth";
import { getSchool, getSchools } from "../../requests/school";
import { getStaffs, isUserAdmin } from "../../requests/user";
import { postGroup } from "../../requests/group";

// drawer
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import Button from "../../../components/Buttons/Button";

// form
import Structure from "../../../components/Forms/Structure";
import { Controller, useForm } from "react-hook-form";
import cogoToast from "cogo-toast";


const CreateGroup = ({ t, TriggerComponent, reloadData }) => {
  const { register, handleSubmit, reset, control, formState: { errors }, getValues, ...api } = useForm();
  const [admin, setAdmin] = useState(false);
  const [allstaffs, setAllStaffs] = useState([]);
  const [schools, setSchools] = useState({});
  const [staffs, setStaffs] = useState([]);

  function handleOnOpen() {
    var user = getUser()

    isUserAdmin().then(isAdmin => {
      if (isAdmin) {
        setAdmin(true);
        getSchools().then(schs => {
          setSchools(schs);
        });


      } else {
        getSchool().then(sch => {
          setSchools([sch]);
          getStaffs([sch.uai], null, true).then(users => {
            setStaffs(users);
            if (user.localAccountId) {
              reset({
                school: sch.uai,
                owner: user.localAccountId
              })
            }
          })
        });
      }
    })
  }

  function onSubmit(data, reset, setOpen) {
    postGroup(data).then(res => {
      if (res.ok) {
        cogoToast.success(
          t("Group created"),
          { position: "bottom-right" }
        )
        return res.json()
      } else {
        cogoToast.error(
          t("Error on create group") + " : " + res.status,
          { position: "bottom-right" }
        )
      }
    }).then(res => {
      if (res) {
        reset();
        setOpen(false);
        reloadData(res);
      }
    })
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
      {(setOpen) => (
        <form
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
          onSubmit={handleSubmit((data) => onSubmit(data, reset, setOpen), reset)}
        >
          <div className="flex-1">
            <Header title={t("Create group")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="school"
                title={t("School") + " *"}
                error={errors.school}
                component={() => (
                  <Controller
                    name="school"
                    id="input-school"
                    control={control}
                    render={({ field }) => (
                      <select
                        name="school"
                        id="input-school"
                        type="text"
                        className={admin ? "block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2" : "block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"}
                        {...field}
                        required
                        disabled={!admin}
                        onChange={(e) => {
                          if (admin) {

                            var value = e.target.value;
                            var name = getValues("name");
                            var sch = value
                            if (schools.find(s => s.uai === value)) {
                              sch = schools.find(s => s.uai === value).ville;
                            }
                            if (sch && name) {
                              api.setValue("displayName", name + " - " + sch);
                            }

                            getStaffs([value], null, true).then(users => {
                              setStaffs(users)
                            })
                            field.onChange(value);
                          }
                        }}
                      >
                        {admin && <option />}
                        {schools && schools.length ? schools.map(st => <option key={st.uai} value={st.uai}>{st.intitule}</option>) : null}
                      </select>
                    )}
                  />
                )}
              />
              <Structure
                id="name"
                title={t("Name") + " *"}
                error={errors.name}
                component={() => (
                  <Controller
                    name="name"
                    id="input-name"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="text"
                        className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                        {...field}
                        required
                        onChange={(e) => {
                          var value = e.target.value;
                          var sch = getValues("school")
                          if (schools.find(s => s.uai === getValues("school"))) {
                            sch = schools.find(s => s.uai === getValues("school")).ville;
                          }
                          if (sch) {
                            api.setValue("displayName", value + " - " + sch);
                          }
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                )}
              />
              <Structure
                id="displayName"
                title={t("Display name") + " *"}
                error={errors.displayName}
                component={() => (
                  <input
                    name="displayName"
                    id="input-displayName"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                    {...register("displayName", { required: true })}
                    readOnly
                  />
                )}
              />
              <Structure
                id="description"
                title={t("Description") + " *"}
                error={errors.description}
                component={() => (
                  <input
                    name="description"
                    id="input-description"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    {...register("description", { required: true })}
                  />
                )}
              />
              <Structure
                id="owner"
                error={errors.owner}
                title={t("Owner") + " *"}
                component={() => (
                  <select
                    name="owner"
                    id="input-owner"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    {...register("owner", { required: true })}
                  >
                    <option />
                    {staffs && staffs.map(st => <option key={st.id} value={st.id}>{st.displayName}</option>)}
                  </select>
                )}
              />
              <Structure
                id="template"
                error={errors.template}
                title={t("Template") + " *"}
                component={() => (
                  <select
                    name="template"
                    id="input-template"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    {...register("template", { required: true })}
                  >
                    <option />
                    <option key="educationClass" value="educationClass">{t("Classe")}</option>
                    {/* <option key="educationProfessionalLearningCommunity" value="educationProfessionalLearningCommunity">{t("Communauté de formation professionnelle (PLC)")}</option> */}
                    <option key="educationStaff" value="educationStaff">{t("Personnel")}</option>
                    <option key="standard" value="standard">{t("Autre")}</option>
                  </select>
                )}
              />
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => { reset(); setOpen(false); }}
              >
                {t("Cancel")}
              </button>
              <Button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                {t("Submit")}
              </Button>
            </>
          </Footer>
        </form>
      )}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(CreateGroup));
