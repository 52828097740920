import React, { useEffect, useState } from "react";

function Checkbox({ value, onClick }) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(()=>{
        setIsChecked(value ? true : false);
    }, [value])

    return <input type="checkbox" className="form-checkbox h-5 w-5 text-green-600"
        checked={isChecked}
        onChange={(event) => {
            var valueUpdated = event.target.checked;
            setIsChecked(valueUpdated);
            onClick(valueUpdated);
        }} />
}

export default React.memo(Checkbox);