import React from "react";

// language
import { I18nextProvider } from "react-i18next";
import i18n from "./locale/i18n";

import Router from "./utils/router/router";

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  );
}

export default App;



