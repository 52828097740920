import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import Structure from "../../../components/Forms/Structure";
import { getSchool, getSchoolByCity } from "../../requests/school";
import { getSchoolUsers, isUserAdmin } from "../../requests/user";
import { getGroupMembers, getGroupOwners, getGroupsByIds, postGroupMember, deleteGroups, postGroupOwner, removeGroupOwner } from "../../requests/group";
import columnsGroupsMembers from "../../tables/GroupsMembers";
import Table from "../../../components/Tables/Table";
import Button from "../../../components/Buttons/Button";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import { PlusIcon } from "@heroicons/react/outline";
import cogoToast from "cogo-toast";
import ImportGroupMember from "./importGroupMember";

const UpdateGroup = ({ t, TriggerComponent, id, reloadData }) => {
  const [error, setError] = useState(false);
  const [school, setSchool] = useState({});
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [owners, setOwners] = useState([]);
  const [memberToAdd, setMemberToAdd] = useState("");
  const [group, setGroup] = useState({});
  const [loadingDeletion, setLoadingDeletion] = useState(false);
  const [loadingOwner, setLoadingOwner] = useState(false);

  function handleOnOpen() {
    isUserAdmin().then(isAdmin => {
      if (isAdmin) {
        if (id) {
          getGroupsByIds([id]).then(groups => {
            if (groups) {
              var groupAPI = groups[0];
              setGroup(groupAPI);

              var cityFromGroupName = groupAPI.displayName.split(" - ")[0] + " - " + groupAPI.displayName.split(" - ")[1];
              getSchoolByCity(cityFromGroupName).then(schoolAPI => {
                setSchool(schoolAPI);
                if (schoolAPI && schoolAPI.uai) {
                  getSchoolUsers(schoolAPI.uai).then(usersAPI => {
                    setUsers(usersAPI)
                  })
                }
              })
            } else {
              setError(true);
            }
          });

          getGroupMembers(id).then(membersAPI => {
            setMembers(membersAPI)
          })

          getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
        } else {
          setError(true);
        }
      } else {
        getSchool().then(sch => {
          setSchool(sch);
          getSchoolUsers(sch.uai).then(usersAPI => {
            setUsers(usersAPI)
          })
        });
        if (id) {
          getGroupsByIds([id]).then(groups => {
            if (groups) {
              setGroup(groups[0]);
            } else {
              setError(true);
            }
          });

          getGroupMembers(id).then(membersAPI => {
            setMembers(membersAPI)
          })

          getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
        } else {
          setError(true);
        }
      }
    })

  }

  function handlePostMemberToGroup() {
    postGroupMember(memberToAdd, id).then((res) => {
      if (res.ok) {
        cogoToast.success(t("Member added"),
          { position: "bottom-right" })
        return res.json();
      } else {
        cogoToast.error(
          t("Error on add member") + " : " + res.status,
          { position: "bottom-right" }
        )
      }
    }).then(res => {
      if (res && res.id) {
        reloadMember([res]);
      }
    })
  }

  function handleDelete(setOpen) {
    setLoadingDeletion(true);
    deleteGroups([id], t).then(res => {
      if (res) {
        setOpen(false);
        reloadData([{ id: id, deleted: true }]);
      }
    })
  }

  function reloadMember(data) {
    var membersUpdated = members;
    setMembers([])

    if (data && data.length) {
      data.forEach(d => {
        if (d?.deleted) {
          membersUpdated = membersUpdated.filter(s => s.id !== d?.id);
        }
        else {
          var index = membersUpdated.findIndex(s => s.id === d?.id);
          if (index !== -1) {
            membersUpdated[index] = d
          } else {
            membersUpdated.push(d)
          }
        }
      })
      setMembers(membersUpdated);
    }
  }

  function reloadOwner(data) {
    var ownersUpdated = owners;
    setOwners([]);
    if (data && data.length) {
      data.forEach(d => {
        if (d?.deleted) {
          ownersUpdated = ownersUpdated.filter(s => s.id !== d?.id);
        }
        else {
          var index = ownersUpdated.findIndex(s => s.id === d?.id);
          if (index !== -1) {
            ownersUpdated[index] = d
          } else {
            ownersUpdated.push(d)
          }
        }
      })
      setOwners(ownersUpdated);
    }
  }

  function handleSubmitOwner(value, row) {
    setLoadingOwner(true);
    if (value) {
      postGroupOwner(row?.id, id).then(res => {
        setLoadingOwner(false);
        if (res.ok) {
          cogoToast.success(t("Owner added to the group"), { position: "bottom-right" })
          reloadOwner([row])
        } else {
          cogoToast.error(
            t("Error on add owner") + " : " + res.status,
            { position: "bottom-right" }
          )
        }
      })
    } else {
      removeGroupOwner(row?.id, id).then(res => {
        setLoadingOwner(false);
        if (res.ok) {
          cogoToast.success(t("Owner remove from the group"), { position: "bottom-right" })
          reloadOwner([{ id: row?.id, deleted: true }])
        } else {
          cogoToast.error(
            t("Error on remove owner") + " : " + res.status,
            { position: "bottom-right" }
          )
        }
      })
    }
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
      {(setOpen) => (
        <div
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
        >
          <div className="flex-1">
            <Header title={t("Group")} setOpen={setOpen}
              buttons={!error &&
                <>
                  <Button loading={loadingDeletion}
                    className="ml-2 mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={() => { handleDelete(setOpen) }}
                  >{t("Delete")}</Button>
                </>}
            />
            {error && <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
              <p className="font-bold">{t("Please wait")}</p>
              <p>{t("An error occured. Please retry in few minutes")}</p>
            </div>}
            {!error && <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="school"
                title={t("School")}
                component={() => (
                  <input
                    name="school"
                    id="input-school"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                    value={school && school.intitule}
                    readOnly
                  />
                )}
              />
              <Structure
                id="name"
                title={t("Name")}
                component={() => (
                  <input
                    name="displayName"
                    id="input-displayName"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                    defaultValue={group.displayName}
                    readOnly
                  />
                )}
              />
              <Structure
                id="description"
                title={t("Description")}
                component={() => (
                  <input
                    name="description"
                    id="input-description"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                    defaultValue={group.description}
                    readOnly
                  />
                )}
              />
              <Structure
                id="mail"
                title={t("Mail")}
                component={() => (
                  <input
                    name="mail"
                    id="input-mail"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 bg-gray-200"
                    defaultValue={group.mail}
                    readOnly
                  />
                )}
              />
              <Structure
                id="member"
                title={t("Add member")}
                component={() => <div className="flex">
                  <select
                    name="search"
                    id="input-search"
                    type="text"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2 mr-2"
                    onClick={(ev) => { setMemberToAdd(ev.target.value) }}
                  >
                    <option />
                    {users.filter(u => members && !members.find(m => m.id === u.id)).map(st => <option key={st.id} value={st.id}>{st.displayName}</option>)}
                  </select>
                  <Button className="p-2 border-2 rounded-full" key="addMember" onClick={() => {
                    handlePostMemberToGroup()
                  }}><PlusIcon width={20} /></Button>
                </div>}
              />
              <Table data={members} columns={columnsGroupsMembers(t, owners, id, reloadMember, handleSubmitOwner, loadingOwner)} actions={[<Button
                key="refreshMembers"
                onClick={() => {
                  isUserAdmin().then(isAdmin => {
                    if (isAdmin) {
                      if (id) {
                        getGroupsByIds([id]).then(groups => {
                          if (groups) {
                            var groupAPI = groups[0];
                            setGroup(groupAPI);

                            var cityFromGroupName = groupAPI.displayName.split(" - ")[0] + " - " + groupAPI.displayName.split(" - ")[1];
                            getSchoolByCity(cityFromGroupName).then(schoolAPI => {
                              setSchool(schoolAPI);
                              if (schoolAPI && schoolAPI.uai) {
                                getSchoolUsers(schoolAPI.uai).then(usersAPI => {
                                  setUsers(usersAPI)
                                })
                              }
                            })
                          } else {
                            setError(true);
                          }
                        });

                        getGroupMembers(id).then(membersAPI => {
                          setMembers(membersAPI)
                        })

                        getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
                      } else {
                        setError(true);
                      }
                    } else {
                      getSchool().then(sch => {
                        setSchool(sch);
                        getSchoolUsers(sch.uai).then(usersAPI => {
                          setUsers(usersAPI)
                        })
                      });
                      if (id) {
                        getGroupsByIds([id]).then(groups => {
                          if (groups) {
                            setGroup(groups[0]);
                          } else {
                            setError(true);
                          }
                        });

                        getGroupMembers(id).then(membersAPI => {
                          setMembers(membersAPI)
                        })

                        getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
                      } else {
                        setError(true);
                      }
                    }
                  })
                }}
              >
                {t("Refresh")}
              </Button>, <ImportGroupMember
                key="importGroup"
                school={school}
                groupId={group.id}
                TriggerComponent={(onClick, loading) => (
                  <Button loading={loading} onClick={() => onClick()}>
                    {t("Import Members")}
                  </Button>
                )}
                reloadData={() => {
                  isUserAdmin().then(isAdmin => {
                    if (isAdmin) {
                      if (id) {
                        getGroupsByIds([id]).then(groups => {
                          if (groups) {
                            var groupAPI = groups[0];
                            setGroup(groupAPI);

                            var cityFromGroupName = groupAPI.displayName.split(" - ")[0] + " - " + groupAPI.displayName.split(" - ")[1];
                            getSchoolByCity(cityFromGroupName).then(schoolAPI => {
                              setSchool(schoolAPI);
                              if (schoolAPI && schoolAPI.uai) {
                                getSchoolUsers(schoolAPI.uai).then(usersAPI => {
                                  setUsers(usersAPI)
                                })
                              }
                            })
                          } else {
                            setError(true);
                          }
                        });

                        getGroupMembers(id).then(membersAPI => {
                          setMembers(membersAPI)
                        })

                        getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
                      } else {
                        setError(true);
                      }
                    } else {
                      getSchool().then(sch => {
                        setSchool(sch);
                        getSchoolUsers(sch.uai).then(usersAPI => {
                          setUsers(usersAPI)
                        })
                      });
                      if (id) {
                        getGroupsByIds([id]).then(groups => {
                          if (groups) {
                            setGroup(groups[0]);
                          } else {
                            setError(true);
                          }
                        });

                        getGroupMembers(id).then(membersAPI => {
                          setMembers(membersAPI)
                        })

                        getGroupOwners(id).then(ownersAPI => setOwners(ownersAPI))
                      } else {
                        setError(true);
                      }
                    }
                  })
                }}
              />]} />
            </div>}
          </div>
        </div>)}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(UpdateGroup));
