import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import Table from "../../../components/Tables/Table";
import columnsImportStudents from "../../tables/ImportStudent";
import handleCSVImport from "../../functions/handleCSVImport";
import {
  getUserByUserPrincipalName,
  isUserAdmin,
  patchUsers,
  postUsers,
} from "../../requests/user";
import Structure from "../../../components/Forms/Structure";
import generateDisplayName from "../../functions/generateDisplayName";
import generateO356ID from "../../functions/generateO365ID";
import generatePassword from "../../functions/generatePassword";
import generateMailNickname from "../../functions/generateMailNickname";
import { getSchool, getSchools } from "../../requests/school";
import cogoToast from "cogo-toast";
import Button from "../../../components/Buttons/Button";
import { CSVLink } from "react-csv";
import { postMail } from "../../requests/mail";
import { divideChunks } from "../../functions/divideChunks";
import columnsResetPasswordUsers from "../../tables/ResetPasswordUsers";

const dataExample = [
  {
    surname: "Nom de famille 1",
    givenName: "Prénom 1",
    officeLocation: "Classe 1",
    school: "00000000",
  },
  {
    surname: "Nom de famille 2",
    givenName: "Prénom 2",
    officeLocation: "Classe 2",
    school: "00000000",
  },
];

const columnAdminExample = [
  {
    label: "Nom",
    key: "surname",
  },
  {
    label: "Prénom",
    key: "givenName",
  },
  {
    label: "Classe",
    key: "officeLocation",
  },
  {
    label: "Code Etablissement",
    key: "school",
  },
];

const columnStaffExample = [
  {
    label: "Nom",
    key: "surname",
  },
  {
    label: "Prénom",
    key: "givenName",
  },
  {
    label: "Classe",
    key: "officeLocation",
  },
];

const ImportStudent = ({ t, TriggerComponent, reloadData }) => {
  const [admin, setAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const [school, setSchool] = useState({});
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(false);

  function handleOnOpen() {
    isUserAdmin().then((isAdmin) => {
      setAdmin(isAdmin);
      if (isAdmin) {
        getSchools().then((schools) => {
          setSchools(schools);
        });
      } else {
        getSchool().then((sch) => setSchool(sch));
      }
    });
  }

  function handleImportCSVStudent(e) {
    handleCSVImport(e, (data) => {
      var promises = [];
      data.forEach((d) => {
        d.errors = [];
        d.givenName = d.Prénom || d.Prenom;
        d.surname = d.Nom;
        d.officeLocation = d.Classe;
        d.displayName = generateDisplayName(d.givenName, d.surname);
        d.jobTitle = "Eleve";
        d.passwordProfile = {
          forceChangePasswordNextSignIn: "true",
          password: generatePassword(8),
        };
        d.mailNickname = generateMailNickname(d.givenName, d.surname);
        d.userPrincipalName =
          d.givenName
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "")
            .split(" ")
            .join("")
            .split("'")
            .join("") +
          "." +
          d.surname
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(" ", "")
            .split(" ")
            .join("")
            .split("'")
            .join("") +
          "@";

        if (!d.givenName) {
          d.errors.push(t("Given name is required"));
        }

        if (!d.surname) {
          d.errors.push(t("Surname is required"));
        }

        if (!d.officeLocation) {
          d.errors.push(t("Classroom is required"));
        }

        if (admin && schools && schools.length) {
          var schoolAd = schools.find(
            (sch) => sch.uai === d["Code Etablissement"]
          );
          if (schoolAd && schoolAd.intitule) {
            d.userPrincipalName += schoolAd.domaine;
            d.school = schoolAd.intitule;
            d.state = d["Code Etablissement"];
          } else {
            d.errors.push(
              t("School not find") + " : '" + d["Code Etablissement"] + "'"
            );
          }
        } else if (school && school.uai) {
          d.userPrincipalName += school.domaine;
          d.state = school.uai;
        } else {
          d.errors.push(
            t("School not find") + " : '" + d["Code Etablissement"] + "'"
          );
        }

        if (d.userPrincipalName && d.userPrincipalName !== null) {
          promises.push(
            getUserByUserPrincipalName(d.userPrincipalName)
              .then((res) => {
                if (res.ok) {
                  return res.json();
                } else {
                  return false;
                }
              })
              .then((res) => {
                if (res) {
                  d.type = "Mise à jour";
                  if (res.jobTitle === "Eleve") {
                    d.id = res.id;
                  } else {
                    d.errors.push(t("Unhautorized to update staff account"));
                  }
                } else {
                  d.type = "Création";
                }
                return d;
              })
          );
        } else {
          d.errors.push(
            t("Mail not found") + " : '" + d.userPrincipalName + "'"
          );
          promises.push(generateO356ID("", "", "").then((u) => d));
        }
      });
      Promise.all(promises).then((dataFormated) => {
        setUsers(dataFormated);
      });
    });
  }

  function verifyRow(row) {
    row.errors = [];
    row.displayName = generateDisplayName(row.givenName, row.surname);
    row.jobTitle = "Eleve";
    row.mailNickname = generateMailNickname(row.givenName, row.surname);

    if (!row.givenName) {
      row.errors.push(t("Given name is required"));
    }

    if (!row.surname) {
      row.errors.push(t("Surname is required"));
    }

    if (!row.officeLocation) {
      row.errors.push(t("Classroom is required"));
    }

    row.userPrincipalName =
      row.givenName
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(" ", "")
        .split(" ")
        .join("")
        .split("'")
        .join("") +
      "." +
      row.surname
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(" ", "")
        .split(" ")
        .join("")
        .split("'")
        .join("") +
      "@";

    if (admin && schools && schools.length) {
      var schoolAd = schools.find((sch) => sch.uai === row?.state);
      if (schoolAd && schoolAd.intitule) {
        row.userPrincipalName += schoolAd.domaine;
        row.school = schoolAd.intitule;
        row.state = schoolAd.uai;
      } else {
        row.errors.push(
          t("School not find") + " : '" + row["Code Etablissement"] + "'"
        );
      }
    } else if (school && school.uai) {
      row.state = school.uai;
      row.userPrincipalName += school.domaine;
    }

    return getUserByUserPrincipalName(row.userPrincipalName)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return false;
        }
      })
      .then((res) => {
        if (res) {
          row.type = "Mise à jour";
          if (res.jobTitle === "Eleve") {
            row.id = res.id;
          } else {
            row.errors.push(t("Unhautorized to update staff account"));
          }
        } else {
          row.type = "Création";
        }
        return row;
      });
  }

  function replaceRowInList(row, key) {
    var usersUpdated = users;
    setUsers([]);
    usersUpdated[key] = row;
    setUsers(usersUpdated);
  }

  function handleImport(setOpen) {
    setLoading(true);
    var promises = [];
    if (
      users &&
      users.filter((u) => u.type === "Création") &&
      users.filter((u) => u.type === "Création").length > 0
    ) {
      var promisesPost = [];
      var usersPost = users.filter((u) => u.type === "Création");
      var usersPostChunk = divideChunks(usersPost, 50);
      usersPostChunk.forEach((uP) => {
        promisesPost.push(
          postUsers(uP)
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                cogoToast.error(
                  t("Error on create users") + " : " + res.status,
                  { position: "bottom-right" }
                );
              }
            })
            .then((res) => {
              if (res && res.users) {
                return res;
              } else {
                return [];
              }
            })
        );
      });
      promises.push(
        Promise.all(promisesPost).then((usersList) => {
          var resultPost = { users: [], errors: [], amountErrors: 0 };

          usersList.forEach((u) => {
            resultPost.users = resultPost.users.concat(u.users);
            resultPost.errors = resultPost.errors.concat(u.errors);
            resultPost.amountErrors += u.amountErrors;
          });

          if (resultPost.users.length > 0) {
            cogoToast.success(
              t("x users created", { x: resultPost.users.length }),
              { position: "bottom-right" }
            );
          }

          return resultPost;
        })
      );
    } else {
      promises.push(() => {
        return { users: [], errors: [], amountErrors: 0 };
      });
    }

    if (
      users &&
      users.filter((u) => u.type === "Mise à jour") &&
      users.filter((u) => u.type === "Mise à jour").length > 0
    ) {
      var promisesPatch = [];
      var usersPatch = users.filter((u) => u.type === "Mise à jour");
      var usersPatchChunk = divideChunks(usersPatch, 50);
      usersPatchChunk.forEach((uP) => {
        promisesPatch.push(
          patchUsers(uP)
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                cogoToast.error(
                  t("Error on update users") + " : " + res.status,
                  { position: "bottom-right" }
                );
              }
            })
            .then((res) => {
              if (res && res.users) {
                return res;
              } else {
                return [];
              }
            })
        );
      });
      promises.push(
        Promise.all(promisesPatch).then((usersList) => {
          var resultPatch = { users: [], errors: [], amountErrors: 0 };
          usersList.forEach((u) => {
            resultPatch.users = resultPatch.users.concat(u.users);
            resultPatch.errors = resultPatch.errors.concat(u.errors);
            resultPatch.amountErrors += u.amountErrors;
          });

          if (resultPatch.users.length > 0) {
            cogoToast.success(
              t("x users updated", { x: resultPatch.users.length }),
              { position: "bottom-right" }
            );
          }
          return resultPatch;
        })
      );
    } else {
      promises.push(() => {
        return { users: [], errors: [], amountErrors: 0 };
      });
    }

    Promise.all(promises)
      .then(([usersPost, usersPatch]) => {
        var errors = [];
        if (admin) {
          schools.forEach((sch) => {
            if (
              (usersPost &&
                usersPost.users &&
                usersPost.users.length &&
                usersPost.users.find((u) => u.state === sch.uai)) ||
              (usersPatch &&
                usersPatch.users &&
                usersPatch.users.length &&
                usersPatch.users.find((u) => u.state === sch.uai))
            ) {
              //handleMail
              var content =
                "Bonjour,<br /><br />Voici la liste des comptes Office 365 créés ou modifiés via votre import CSV :<br />";
              if (
                usersPost &&
                usersPost.users &&
                usersPost.users.length &&
                usersPost.users.filter((u) => u.state === sch.uai) &&
                usersPost.users.filter((u) => u.state === sch.uai).length
              ) {
                usersPost.users
                  .filter((u) => u.state === sch.uai)
                  .forEach((uP) => {
                    content +=
                      "(Création);" +
                      uP.officeLocation +
                      ";" +
                      uP.displayName +
                      ";" +
                      uP.userPrincipalName +
                      ";Mot de passe : " +
                      uP.passwordProfile.password +
                      "<br />";
                  });
              }
              if (
                usersPatch &&
                usersPatch.users &&
                usersPatch.users.length &&
                usersPatch.users.filter((u) => u.state === sch.uai) &&
                usersPatch.users.filter((u) => u.state === sch.uai).length
              ) {
                content += "<br />";
                usersPatch.users
                  .filter((u) => u.state === sch.uai)
                  .forEach((uP) => {
                    content +=
                      "(Modification);" +
                      uP.officeLocation +
                      ";" +
                      uP.displayName +
                      ";" +
                      uP.userPrincipalName +
                      "<br />";
                  });
              }

              if (
                usersPost &&
                usersPatch &&
                usersPost.amountErrors + usersPatch.amountErrors > 0
              ) {
                errors = usersPost.errors.concat(usersPatch.errors);
              } else if (
                usersPost &&
                usersPost.amountErrors > 0 &&
                !usersPatch
              ) {
                errors = usersPost.errors;
              } else if (
                usersPatch &&
                usersPatch.amountErrors > 0 &&
                !usersPost
              ) {
                errors = usersPatch.errors;
              }

              if (errors && errors.length) {
                content +=
                  "<br /><br />Des erreurs ont eu lieu lors de l'importation : ";
                errors.forEach((uP) => {
                  content += uP + "<br />";
                });
              }

              content +=
                "<br />Cordialement,<br /><br />Votre administrateur Office 365";
              var recipients = sch.administrateur;
              postMail("Import CSV", content, recipients);
            } else {
              if (usersPost.amountErrors + usersPatch.amountErrors > 0) {
                //handleMail
                content =
                  "Bonjour,<br /><br />Des erreurs ont eu lieu lors de votre import CSV :<br />";
                errors = usersPost.errors.concat(usersPatch.errors);
                if (errors && errors.length) {
                  errors.forEach((uP) => {
                    content += uP + "<br />";
                  });
                }
                content +=
                  "<br />Cordialement,<br /><br />Votre administrateur Office 365";
                recipients = sch.administrateur;
                postMail(
                  "Import CSV : " +
                    (usersPost.amountErrors + usersPatch.amountErrors) +
                    " erreurs",
                  content,
                  recipients
                );
              }
            }
          });
        } else {
          //handleMail
          var content =
            "Bonjour,<br /><br />Voici la liste des comptes Office 365 créés ou modifiés via votre import CSV :<br />";
          if (usersPost && usersPost.users && usersPost.users.length) {
            usersPost.users.forEach((uP) => {
              content +=
                "(Création);" +
                uP.officeLocation +
                ";" +
                uP.displayName +
                ";" +
                uP.userPrincipalName +
                ";Mot de passe : " +
                uP.passwordProfile.password +
                "<br />";
            });
          }
          if (usersPatch && usersPatch.users && usersPatch.users.length) {
            content += "<br />";
            usersPatch.users.forEach((uP) => {
              content +=
                "(Modification);" +
                uP.officeLocation +
                ";" +
                uP.displayName +
                ";" +
                uP.userPrincipalName +
                "<br />";
            });
          }

          if (
            usersPost &&
            usersPatch &&
            usersPost.amountErrors + usersPatch.amountErrors > 0
          ) {
            errors = usersPost.errors.concat(usersPatch.errors);
          } else if (usersPost && usersPost.amountErrors > 0 && !usersPatch) {
            errors = usersPost.errors;
          } else if (usersPatch && usersPatch.amountErrors > 0 && !usersPost) {
            errors = usersPatch.errors;
          }
          if (errors && errors.length) {
            content +=
              "<br /><br />Des erreurs ont eu lieu lors de l'importation : ";
            errors.forEach((uP) => {
              content += uP + "<br />";
            });
          }

          content +=
            "<br />Cordialement,<br /><br />Votre administrateur Office 365";
          var recipients = school.administrateur;
          postMail("Import CSV", content, recipients);
        }
        if (usersPatch && usersPatch.users && usersPatch.users.length) {
          if (usersPost && usersPost.users && usersPost.users.length) {
            reloadData(usersPatch.users.concat(usersPost.users));
          } else {
            reloadData(usersPatch.users);
          }
        } else {
          if (usersPost && usersPost.users && usersPost.users.length) {
            reloadData(usersPost.users);
          } else {
            reloadData([]);
          }
        }

        setLoading(false);
        setUsers([]);
        setOpen(false);
      })
      .catch((errors) => {
        setLoading(false);
        setUsers([]);
        cogoToast.error(t("An error occured") + " : " + errors, {
          position: "bottom-right",
        });
      });
  }

  return (
    <WideDrawer
      handleOnOpen={handleOnOpen}
      TriggerComponent={(onClick) => TriggerComponent(onClick, loading)}
    >
      {(setOpen) => (
        <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
          <div className="flex-1">
            <Header title={t("Import student")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Structure
                id="sample"
                title={t("Example")}
                component={() => (
                  <>
                    <CSVLink
                      data={dataExample}
                      headers={admin ? columnAdminExample : columnStaffExample}
                      separator={";"}
                      className=""
                      filename="ImportExemple.csv"
                    >
                      <Button>{t("Download example")}</Button>
                    </CSVLink>
                  </>
                )}
              />
              <Structure
                id="file"
                title={t("File") + " *"}
                component={() => (
                  <input
                    type="file"
                    name="uploadFile"
                    id="uploadfile"
                    className="block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                    onChange={handleImportCSVStudent}
                  />
                )}
              />
              {users && users.length ? (
                <Table
                  key="usersToImport"
                  data={users}
                  columns={columnsImportStudents(
                    t,
                    admin,
                    schools,
                    (row, key) => {
                      verifyRow(row, key).then((data) => {
                        replaceRowInList(data, key);
                      });
                    }
                  )}
                />
              ) : null}
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => setOpen(false)}
              >
                {t("Cancel")}
              </button>
              <Button
                className={
                  users &&
                  users.length &&
                  !users.find((user) => user.errors && user.errors.length)
                    ? "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    : "inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 cursor-not-allowed"
                }
                disabled={
                  !users ||
                  !users.length ||
                  users.find((user) => user.errors && user.errors.length)
                }
                loading={loading}
                onClick={() => handleImport(setOpen)}
              >
                {t("Import")}
              </Button>
            </>
          </Footer>
        </div>
      )}
    </WideDrawer>
  );
};

export default React.memo(withTranslation()(ImportStudent));
