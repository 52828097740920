import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import WideDrawer from "../../../components/Drawer/WideDrawer";
import Header from "../../../components/Drawer/Header";
import Footer from "../../../components/Drawer/Footer";
import { deleteGroups, getGroupsByIds } from "../../requests/group";
import Table from "../../../components/Tables/Table";
import columnsGroups from "../../tables/Groups";
import cogoToast from "cogo-toast";

const DeleteGroups = ({ t, TriggerComponent, groupsId, reloadData }) => {
  const [groups, setGroups] = useState([]);

  function handleOnOpen() {
    getGroupsByIds(groupsId).then(groups => {
      setGroups(groups)
    })
  }

  return (
    <WideDrawer handleOnOpen={handleOnOpen} TriggerComponent={TriggerComponent}>
      {(setOpen) => (
        <div
          className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll"
        >
          <div className="flex-1">
            <Header title={t("Delete groups")} description={t("Are you sure to delete all the groups listed below ?")} setOpen={setOpen} />
            <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
              <Table key="groupsToDelete" data={groups} columns={columnsGroups(t)} />
            </div>
          </div>
          <Footer>
            <>
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => setOpen(false)}
              >
                {t("Cancel")}
              </button>
              <button
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => {
                  deleteGroups(groupsId, t).then(res => {
                    if (res) {
                      cogoToast.success(t("Groups deleted", { amount: res }),
                        { position: "bottom-right" })
                      var deletedGroups = [];
                      groupsId.forEach(id => {
                        deletedGroups.push({ id: id, deleted: true })
                      })
                      reloadData(deletedGroups);
                      setGroups([]);
                      setOpen(false);
                    }
                  });
                }}
              >
                {t("Delete")}
              </button>
            </>
          </Footer>
        </div>
      )}
    </WideDrawer>

  );
};

export default React.memo(withTranslation()(DeleteGroups));
