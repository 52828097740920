const configuration = {
    auth: {
        authority: "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANTID + "/",
        clientId: process.env.REACT_APP_CLIENTID,
        redirectUri: process.env.REACT_APP_REDIRECTURI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
};

const loginRequest = {
    scopes: ["user.read", "User.Read.All"]
}

const protectedResources = {
    api: {
        endpoint: window.location.origin + "/api",
        scopes: ["api://" + process.env.REACT_APP_API_CLIENTID + "/school.readwrite.all",
        "api://" + process.env.REACT_APP_API_CLIENTID + "/user.write"
        ],
    },
}


export {
    configuration,
    loginRequest,
    protectedResources
};
