import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

export default function columnsImportGroups(t, isAdmin, staffs, schools, handleRowChange) {

  var col = [
    {
      key: "status",
      visible: true,
      label: t("Status"),
      id: "status",
      width: 300,
      format: (row) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          {row.errors && row.errors.length ? <XCircleIcon color="red" width={30} /> : <CheckCircleIcon color="green" width={30} />}
        </span>
      ),
    },
    {
      key: "name",
      visible: true,
      label: t("Name"),
      id: "name",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <input
            name="name"
            id="input-name"
            type="text"
            required
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            defaultValue={row.name}
            onBlur={(e) => {
              var value = e.target.value;
              row.Nom = value;
              handleRowChange(row, key);
            }}
          />
        </span>
      ),
    },
    {
      key: "displayName",
      visible: true,
      label: t("Display name"),
      id: "displayName",
      width: 300,
      format: (row) => (
        <span>{row.displayName}</span>
      ),
    },
    {
      key: "description",
      visible: true,
      label: t("Description"),
      id: "description",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <input
            name="description"
            id="input-description"
            type="text"
            required
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            defaultValue={row.description}
            onBlur={(e) => {
              var value = e.target.value;
              row.Description = value;
              handleRowChange(row, key);
            }}
          />
        </span>
      ),
    },
    {
      key: "template",
      visible: true,
      label: t("Template"),
      id: "template",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <select
            name="template"
            id="input-template"
            type="text"
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            required
            value={row.template}
            onChange={(e) => {
              row.Modèle = e.target.value;
              handleRowChange(row, key);
            }}
          >
            <option key="educationClass" value="educationClass">{t("Classe")}</option>
            {/* <option key="educationProfessionalLearningCommunity" value="educationProfessionalLearningCommunity">{t("Communauté de formation professionnelle (PLC)")}</option> */}
            <option key="educationStaff" value="educationStaff">{t("Personnel")}</option>
            <option key="standard" value="standard">{t("Autre")}</option>
          </select>
        </span>
      ),
    }
  ];
  if (isAdmin) {
    col.push({
      key: "school",
      visible: true,
      label: t("School"),
      id: "school",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <select
            name="school"
            id="input-school"
            type="text"
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            required
            value={row.state}
            onChange={(e) => {
              var value = e.target.value;
              row["Code Etablissement"] = value;
              row.owner = null;
              row.owners = []
              handleRowChange(row, "state");
            }}
          >
            <option />
            {schools &&
              schools.map((sch, key) => (
                <option key={key} value={sch.uai}>
                  {sch.intitule}
                </option>
              ))}
          </select>
        </span>
      ),
    }, {
      key: "owner",
      visible: true,
      label: t("Owner"),
      id: "owner",
      width: 300,
      format: (row, key) => {
        if (row.state) {
          return <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
              <select
                name="owner"
                id="input-owner"
                type="text"
                className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
                required
                value={row.owner}
                onChange={(e) => {
                  row.owners = [staffs?.find(st => st.id === e.target.value)?.userPrincipalName]
                  row.owner = e.target.value;
                  handleRowChange(row, key);
                }}
              >
                <option />
                {staffs?.filter(st => st.state ==row?.state)?.map((staff, key) => (
                  <option key={key} value={staff.id}>
                    {staff.displayName}
                  </option>
                ))}
              </select>
            </span>
        } else { return <div>{t("Choose your school first")}</div> }
      }
    })
  } else {
    col.push({
      key: "owner",
      visible: true,
      label: t("Owner"),
      id: "owner",
      width: 300,
      format: (row, key) => (
        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
          <select
            name="owner"
            id="input-owner"
            type="text"
            className="block w-50 shadow-sm sm:text-sm border border-gray-300 rounded-md p-2"
            required
            value={row.owner}
            onChange={(e) => {
              row.owner = e.target.value;
              handleRowChange(row, key);
            }}
          >
            <option />
            {staffs &&
              staffs.map((staff, key) => (
                <option key={key} value={staff.id}>
                  {staff.displayName}
                </option>
              ))}
          </select>
        </span>
      ),
    })
  }
  col.push({
    key: "errors",
    visible: true,
    label: t("Errors"),
    id: "errors",
    width: 300,
    format: (row) => (
      <ul>
        {row.errors && row.errors.length
          ? row.errors.map((error, key) => <li key={key}>{error}</li>)
          : null}
      </ul>
    ),
  });

  return col;
}
