import React from "react";
import SectionTitle from "../Text/SectionTitle";

function Card({ title, children, className }) {
  return (
    <div className={className ? className : "bg-white shadow sm:rounded-lg"}>
      <div className="py-5">
        {title && <SectionTitle>{title}</SectionTitle>}
        <div className="flow-root">
          {children}
        </div>
      </div>
    </div>)
}

export default React.memo(Card)